// @ts-check
import {
  Dialog,
  Divider,
  Drawer,
  Fade,
  InputAdornment,
  Menu,
  MenuItem,
  Slide,
  TableCell,
  TextField,
  Tooltip,
  useTheme,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react'
import { format } from 'date-fns'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import {
  ConfirmModalComponent,
  FieldDataTypeMenuSection,
  InlineEditableValue,
  SquareIconButton,
  Typography,
} from '~/legacy/components'
import {
  SnackbarUtils,
  arrayMove,
  BACKEND_FOR_DND,
  getViewBuildingRoute,
} from '~/legacy/utils'
import {
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_HELPERS,
} from '~/legacy/utils/bulkImportUtils'

import {
  EditableDateCell,
  EditableMultilineStringCell,
  EditableNumberCell,
  EditableStringCell,
} from '~/legacy/components/tableComponents'
import {
  CloseIcon,
  DeleteIcon,
  LockIcon,
  MenuSelectedItemIcon,
  TableClearIcon,
  TextChevronDown,
  DragIcon,
} from '~/legacy/components/svgs'
import { DeleteSpaceFieldModal as DeleteFieldModal } from '~/legacy/components/modalComponents/DeleteSpaceFieldModal'
import { ActionModal2 } from '~/legacy/components/modals/ActionModal2'
import { useTranslation } from '~/locales/translations'

import {
  useCollectionContext,
} from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/collection-context'
import { SearchButton } from '~/legacy/components/search-bar/search-controls'

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

export function TableHeaderCell({ allColumns, header, index, onRefresh }) {
  const {
    survey,
    renameField,
    deleteField,
    changeFieldDataType,
    changeFieldType,
  } = useCollectionContext();

  const theme = useTheme()
  const [newHeader, setNewHeader] = useState({ ...header })
  const [modalName, setModalName] = useState(null)

  const IconComponent = header.icon
  const isReserved = header.reserved

  const isLast = index === allColumns.length - 1
  const isSpacesHeader =
    header.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id

  // If we only have Space Name and Space Notes
  const onlyTwoSpaceColumns = allColumns.length === 2

  useEffect(() => {
    if (header) {
      setNewHeader({ ...header })
    } else {
      setNewHeader({})
    }
  }, [header])

  // Menu stuff
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const anchorRef = React.useRef(null)
  const handleHeaderMenuClick = () => {
    setAnchorEl(anchorRef.current)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    closeMenu()
  }

  const handleRenameField = () => {
    if (newHeader.displayName !== header.displayName) {
      renameField({
        id: header.field.id,
        newName: newHeader.displayName,
      })
    }
  }

  const onCancelChangeFieldDataType = () => {
    setNewHeader({
      ...newHeader,
      fieldDataType: header.fieldDataType,
    })
    setModalName(null)
  }

  const onCancelChangeFieldType = () => {
    setNewHeader({
      ...newHeader,
      fieldType: header.fieldType,
    })
    setModalName(null)
  }

  return (
    <TableCell
      align="center"
      className={clsx(
        'bg-[#F9F9F9] h-12 max-h-12 py-1.5 pr-3 pl-6',
        'border-0 border-solid border-[#E0E0E0] border-l border-t border-b',
        'first:rounded-tl first:sticky first:left-8 first:border-r-2 first:z-[1] last:rounded-tr last:border-r',
        isSpacesHeader && 'border-b first:rounded-bl last:rounded-br',
        onlyTwoSpaceColumns && 'w-1/2',
        index === 1 && 'border-l-0',
        isReserved && 'pr-6'
      )}
      ref={anchorRef}
      data-field-id={header.field?.id || header.displayName}
    >
      <Tooltip
        title={isReserved ? "LeaseUp fields can't be edited or deleted" : ''}
        disableFocusListener
        disableTouchListener
      >
        <div
          className="text-[#666] flex items-center cursor-pointer"
          // onClick={handleHeaderMenuClick}
        >
          <IconComponent className="mr-2" />
          <SearchButton column={header.modelName}>
            <Typography
              noWrap
              variant="body2"
              style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
            >
              {header.displayName.toUpperCase()}
            </Typography>
          </SearchButton>
          <div onClick={handleHeaderMenuClick}>
            {!isReserved ? <TextChevronDown className="text-[#666]" /> : null}
          </div>
        </div>
      </Tooltip>

      {!isReserved ? (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
          className="w-[240px]"
          elevation={2}
          // Open bottom centered
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isLast ? 'right' : 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: isLast ? 'right' : 'center',
          }}
          PaperProps={{
            style: {
              width: '240px',
              minWidth: '240px',
              maxHeight: '375px',
            },
          }}
        >
          <div className="text-[#666] pt-3 pb-1.5 px-3 items-center">
            <Typography variant="h3" className="leading-3 font-med">
              Field Name
            </Typography>
          </div>
          <div className="flex pt-0 px-3 items-center">
            {/* The name of the field */}
            {newHeader.reserved || newHeader.disableAllEditing ? (
              <>
                <Typography className="pt-1.5 pb-[7px]">
                  {newHeader.displayName}
                </Typography>
                <LockIcon className="ml-auto" />
              </>
            ) : (
              <WhiteTextField
                fullWidth
                id="standard-basic"
                value={newHeader.displayName}
                variant="standard"
                color="primary"
                onKeyDown={(e) => {
                  e.stopPropagation()
                  if (e.key === 'Enter') {
                    handleRenameField()
                    closeMenu()
                  }
                }}
                InputProps={{
                  endAdornment:
                    newHeader.reserved || newHeader.disableAllEditing ? (
                      ''
                    ) : (
                      <InputAdornment position="end">
                        <TableClearIcon
                          className="cursor-pointer mr-[-5px] text-[#e0e0e0]"
                          onClick={() => {
                            setNewHeader({ ...newHeader, displayName: '' })
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                        />
                      </InputAdornment>
                    ),
                  autoFocus: true,
                }}
                FormHelperTextProps={{ style: { ...theme.typography.h5 } }}
                onChange={(event) =>
                  setNewHeader({
                    ...newHeader,
                    displayName: event.target.value,
                  })
                }
                onBlur={() => {
                  handleRenameField()
                }}
              />
            )}
          </div>

          {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
            <Divider sx={{ my: 0.5 }} className="items-center mx-3 px-0" />
          )}

          {/* Menu section for the field data type */}
          {!!(!newHeader.reserved && !newHeader.disableAllEditing) && (
            <FieldDataTypeMenuSection
              classesIn={{
                fieldHeaderMenuSection: 'pt-8',
              }}
              onClick={(newFieldDataType) => {
                setNewHeader({
                  ...newHeader,
                  fieldDataType: newFieldDataType,
                })
                setModalName('changeFieldDataType')
                closeMenu()
              }}
              fieldDataTypeId={newHeader.fieldDataType.id}
            />
          )}

          {/* Field types - space or building */}
          {!!(!newHeader.reserved && !newHeader.disableAllEditing) && [
            <div
              key="fieldTypeKey"
              className="text-[#666] pb-1.5 pt-5 px-3 items-center"
            >
              <Typography variant="h3" className="leading-3 font-med">
                Field Type
              </Typography>
            </div>,
            BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_TYPES_ORDERED.map(
              (fieldType) => {
                const FieldIconComponent = fieldType.icon
                return (
                  <MenuItem
                    className="px-3 items-center"
                    disableGutters
                    key={fieldType.id}
                    onClick={() => {
                      setNewHeader({
                        ...newHeader,
                        fieldType,
                      })
                      setModalName('changeFieldType')
                      closeMenu()
                    }}
                  >
                    <FieldIconComponent className="mr-3 text-[#111]" />
                    <Typography color="textPrimary" variant="h3">
                      {fieldType.name}
                    </Typography>
                    {fieldType.id === newHeader.fieldType.id && (
                      <MenuSelectedItemIcon className="ml-auto text-[#666]" />
                    )}
                  </MenuItem>
                )
              }
            ),
          ]}

          {/* Option to remove a field */}
          {!newHeader.disableAllEditing && [
            <div
              key="fieldMatchingKey"
              className="text-[#666] pb-1.5 pt-5 px-3 items-center"
            >
              <Typography variant="h3" className="leading-3 font-med">
                Remove
              </Typography>
            </div>,
            <MenuItem
              key="removeColumn"
              disableGutters
              onClick={() => {
                setModalName('deleteField')
                closeMenu()
              }}
              className="px-3 items-center"
            >
              <DeleteIcon className="mr-3 text-[#111]" />
              <Typography variant="h3">Delete Field</Typography>
            </MenuItem>,
          ]}
        </Menu>
      ) : null}

      <DeleteFieldModal
        fieldName={newHeader.displayName}
        surveyName={survey?.name}
        isCustomField
        deleteSpaceField={() => {
          deleteField({ id: newHeader.field.id })
        }}
        isBuildingField={
          newHeader.fieldType.id ===
          BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
        }
        open={modalName === 'deleteField'}
        onClose={() => setModalName(null)}
        textOverride={`Are you sure you want to delete the ${newHeader.displayName} field? This will also delete its data. This action cannot be undone.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldDataType',
          onClose: onCancelChangeFieldDataType,
        }}
        onClose={onCancelChangeFieldDataType}
        onConfirm={async () => {
          await changeFieldDataType({
            id: newHeader.field.id,
            newType: newHeader.fieldDataType.id,
            newValue: null,
            optimistic: false,
          })
          setModalName(null)
          await onRefresh()
        }}
        title="Change Data Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change ${newHeader.displayName} from ${header.fieldDataType.name} to ${newHeader.fieldDataType.name}? Doing so may cause some or all of the field’s data to be permanently erased.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldType',
          onClose: onCancelChangeFieldType,
        }}
        onClose={onCancelChangeFieldType}
        onConfirm={() => {
          changeFieldType({
            id: newHeader.field.id,
            newType: newHeader.fieldType.templateType,
          })
          setModalName(null)
        }}
        title="Change Field Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change the ${newHeader.displayName} field type from ${header.fieldType.name} to ${newHeader.fieldType.name}? Doing so will cause the field’s data to be permanently erased. This cannot be undone.`}
      />
    </TableCell>
  )
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({ cell, header, onChange }) => {
  const addressValue = {
    GOOGLE_ADDRESS: { ...cell.value },
    ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={onChange}
      value={addressValue || null}
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
      extraProps={{
        isBulkEdit: true,
        buildingUrl: getViewBuildingRoute(cell.surveyId, cell.id),
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
export const NumericFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
export const DateFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

export const ListSemicolonCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={onChange}
    />
  )
}

export const StringCell = ({
  cell,
  header,
  onChange,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

export const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)
export const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
    '& .MuiInputBase-input': {
      height: '22px',
    },
  },
})(TextField)

export const FieldOrderDrawer = ({ open, onClose, fields }) => {
  const fieldType = fields[0].fieldType.name
  const [orderedFields, setOrderedFields] = useState(fields)

  const {
    survey,
    setFieldsOrder,
    deleteField,
  } = useCollectionContext();

  useEffect(() => {
    setOrderedFields(fields)
  }, [fields])

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedFields]
  )

  const didOrderChange = useMemo(() => {
    return (
      fields.map((item) => item.displayName).join(',') !==
      orderedFields.map((item) => item.displayName).join(',')
    )
  }, [fields, orderedFields])

  return (
    <Dialog
      id="dialog-backdrop"
      maxWidth={false}
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <Drawer
        anchor="right"
        classes={{
          paper: 'drawer-width',
        }}
        open={open}
        onClose={onClose}
        variant="persistent"
      >
        <div className="flex border-solid border-0 border-b border-b-[#E0E0E0] pt-[15px] pr-4 pb-4 pl-6">
          <Typography variant="h2" className="flex text-lg leading-6 my-auto">
            Field Order
          </Typography>
          <div className="ml-auto">
            <SquareIconButton onClick={() => onClose()}>
              <CloseIcon />
            </SquareIconButton>
          </div>
        </div>
        <Fade in={open}>
          <div className="p-6">
            <div>
              <Typography variant="bodyBold" className="mb-4">
                {`${fields.length} ${fieldType} Fields`}
              </Typography>
            </div>

            <DndProvider backend={BACKEND_FOR_DND}>
              <div className="flex flex-col bg-[#F9F9F9] rounded py-3 px-2 gap-2">
                {orderedFields.map((item, index) => {
                  return (
                    <DraggableItem
                      key={item?.field?.id || item.displayName}
                      item={item}
                      index={index}
                      moveField={moveField}
                      onDragEnd={() => {
                        const localNewFieldOrder = {}
                        orderedFields.forEach((column, index) => {
                          if (column.reserved) return
                          localNewFieldOrder[column.field.id] = index
                        })

                        if (didOrderChange) {
                          setFieldsOrder({
                            fieldOrder: localNewFieldOrder,
                            isBuildingFields:
                              fieldType ===
                              BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.name,
                          })
                        }
                      }}
                      onDeleteField={deleteField}
                      survey={survey}
                    />
                  )
                })}
              </div>
            </DndProvider>
          </div>
        </Fade>
      </Drawer>
    </Dialog>
  )
}

export const AddFieldModal = ({ open, onClose, type, onAddField, nextOrder }) => {
  const [fieldName, setFieldName] = useState('')
  const { t } = useTranslation()

  const onSave = async () => {
    const { error, data } = await onAddField({
      label: fieldName,
      order: nextOrder,
      type,
    })

    if (error) {
      SnackbarUtils.error(error)
    } else {
      const addedField = data.custom_fields.at(-1)
      SnackbarUtils.success(t('toasts.fieldAdded'))
      setFieldName('')
      onClose()

      // FIXME: Probably find a better way to do this
      if (addedField.id) {
        document
          .querySelector(`th[data-field-id="${addedField.id}"]`)
          .scrollIntoView({
            behavior: 'smooth',
          })
      }
    }
  }

  return (
    <ActionModal2
      open={open}
      title="Add Field"
      action="Add to Survey"
      // loading={isSaving}
      onAction={onSave}
      onClose={() => {
        setFieldName('')
        onClose()
      }}
    >
      <form
        style={{ marginTop: '32px', marginBottom: '40px' }}
        onSubmit={(e) => {
          e.preventDefault()
          onSave()
        }}
      >
        <TextField
          label="Field Name"
          variant="outlined"
          fullWidth
          autoFocus
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
        />
      </form>
    </ActionModal2>
  )
}

const DraggableItem = ({
  item,
  index,
  moveField,
  onDragEnd,
  onDeleteField,
  survey,
}) => {
  const isReserved = item.reserved
  const [modalName, setModalName] = useState(null)

  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index, field: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop() {
      onDragEnd()
    },
  })

  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <div
      className="flex rounded py-2 px-3 bg-white border-solid border border-[#E0E0E0] gap-1.5 items-center"
      ref={!isReserved ? previewRef : null}
      data-handler-id={!isReserved ? handlerId : null}
      style={{ opacity }}
    >
      <SquareIconButton
        tooltipText={isReserved ? 'Can’t move this field' : ''}
        disabled={isReserved}
        ref={ref}
      >
        {isReserved ? <LockIcon /> : <DragIcon className="cursor-grab" />}
      </SquareIconButton>

      <Typography variant="bodyBold" className="grow">
        {item.displayName}
      </Typography>

      <SquareIconButton
        tooltipText={isReserved ? 'Can’t delete this field' : ''}
        disabled={isReserved}
        className={clsx(isReserved && 'opacity-50')}
        onClick={() => {
          if (!isReserved) {
            setModalName('deleteField')
          }
        }}
      >
        <DeleteIcon />
      </SquareIconButton>

      {/* FIXME: Putting this for now. Implement a better way after */}
      <DeleteFieldModal
        fieldName={item.displayName}
        surveyName={survey?.name}
        isCustomField
        deleteSpaceField={() => {
          onDeleteField({ id: item.field.id })
        }}
        isBuildingField={
          item.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
        }
        open={modalName === 'deleteField'}
        onClose={() => setModalName(null)}
        textOverride={`Are you sure you want to delete the ${item.displayName} field? This will also delete its data. This action cannot be undone.`}
      />
    </div>
  )
}

export const cellComponentMap = {
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id]: AddressFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id]: DateFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id]: ListSemicolonCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id]:
    MultilineStringCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id]: StringCell,
}
