import React, { useState, useMemo, Fragment } from 'react'
// @ts-check
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import { Button, Typography, SquareIconButton } from '~/legacy/components'
import { PlusIcon, ReorderIcon } from '~/legacy/components/svgs'
import {
  TableHeaderCell,
  StringCell,
  AddFieldModal,
  FieldOrderDrawer,
  cellComponentMap,
} from './table-components'
import { ToggleFiltersButton } from '~/legacy/components/search-bar/search-controls'
import { SearchBarDrawer } from '~/legacy/components/SearchBar'
import { useColumnStats } from './hooks/useColumnStats'

export function SpacesTab({
  spaceColumns,
  surveyBuildings,
  surveyListings,
  listingValues,
  onValueChange,
  onAddSpace,
  onAddField,
  onRefreshValues,
  showFilters,
  setShowFilters,
  loading,
  isSticky,
}) {
  const [modalName, setModalName] = useState(null)

  const listingCountString =
    surveyListings.length === 1 ? '1 Space' : `${surveyListings.length} Spaces`

  const nextOrder = useMemo(() => {
    const customFields = spaceColumns.filter((column) => !column.reserved)
    if (customFields.length > 0) {
      const lastOrder = customFields[customFields.length - 1].field.order
      return lastOrder ? lastOrder + 1 : undefined
    }

    return undefined
  }, [spaceColumns])

  const data = useMemo(() => {
    const buildingsMap = groupBy(surveyBuildings, 'building.id')
    const valuesByListing = groupBy(listingValues, 'listing_id')
    const listingsByBuilding = groupBy(surveyListings, 'listing.building.id')

    return (
      Object.keys(buildingsMap)
        // Make sure the keys are sorted by the `surveyBuilding` order
        // Since the JS Object's default order does not match our desired order
        .sort((a, b) => buildingsMap[a][0].order - buildingsMap[b][0].order)
        .map((buildingId) => {
          const surveyBuilding = buildingsMap[buildingId][0]
          const { building } = surveyBuilding
          const listings = listingsByBuilding[buildingId] || []

          const mappedListings = listings.map(({ listing }) => {
            const values = []

            spaceColumns.forEach((column) => {
              const modelValue = column.reserved
                ? listing[column.modelName]
                : null
              const customFieldValue = !column.reserved
                ? valuesByListing[listing.id]?.find(
                    (item) => item.custom_field.id === column.field.id
                  )
                : null

              values.push({
                id: column.reserved ? listing.id : customFieldValue?.id,
                value: column.reserved ? modelValue : customFieldValue?.value,
              })
            })

            return {
              ...listing,
              values,
            }
          })

          return { ...building, listings: mappedListings, surveyBuilding }
        })
    )
  }, [surveyBuildings, surveyListings, spaceColumns, listingValues])

  const columnStats = useColumnStats({
    columns: spaceColumns,
    isNested: true
  })

  const HeaderButtons = () => {
    return (
      <>
        <SquareIconButton
          tooltipText="Field Order"
          onClick={() => setModalName('fieldOrder')}
          style={{ marginRight: '8px' }}
        >
          <ReorderIcon />
        </SquareIconButton>

        <Button
          color="primary"
          className="py-0 px-4 h-9 m-auto mr-[1px]"
          onClick={() => setModalName('addField')}
          shrinkButton
        >
          Add Field
        </Button>
      </>
    )
  }

  const TableControls = ({ modals }) => {
    return (
      <>
        <div
          className={clsx(
            'flex justify-between bg-white px-8 py-4 w-full',
            isSticky && 'sticky top-[54px] z-[2]'
          )}
        >
          <div className="flex items-center justify-center gap-2">
            <Typography
              className="my-auto mr-4 leading-7 font-semibold"
              variant="boldText"
            >
              {listingCountString}
            </Typography>
            <ToggleFiltersButton
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>

          <div className="flex items-center gap-2">
            <HeaderButtons />
          </div>
        </div>

        {modals}

        <AddFieldModal
          type="space"
          open={modalName === 'addField'}
          onClose={() => setModalName(null)}
          nextOrder={nextOrder}
          onAddField={onAddField}
        />
      </>
    )
  }

  return (
    <>
      <div className="flex justify-between bg-white sticky top-[54px] left-0 w-[98vw] z-[2]">
        <TableControls modals={null} />
      </div>

      <div className="flex flex-row grow">
        {showFilters && <SearchBarDrawer columnStats={columnStats} />}
        <div className="w-full ml-8">
          <div className="absolute top-[133px] left-0 bottom-4 w-8 bg-white z-10" />

          {loading ? (
            <div>Loading...</div>
          ) : (
            <TableContainer className="flex-none overflow-visible w-full h-full">
              <Table size="small" className="border-separate pr-8">
                <TableHead className="sticky top-[133px] z-[3]">
                  <TableRow>
                    {spaceColumns.map((header, arrayIndex) => {
                      return (
                        <TableHeaderCell
                          key={header.index}
                          allColumns={spaceColumns}
                          index={arrayIndex}
                          header={header}
                          onRefresh={onRefreshValues}
                        />
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((building) => {
                    return (
                      <Fragment key={building.id}>
                        <TableRow>
                          <TableCell
                            className={clsx(
                              'h-[84px] pt-[36px] pb-5 pl-0 whitespace-nowrap border-0',
                              'sticky left-8 z-[1] bg-white border-r-2'
                            )}
                          >
                            <Typography
                              variant="boldText"
                              className="font-semibold text-sm leading-7"
                            >
                              {building.address}
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={spaceColumns.length - 1}
                            className="border-0 bg-white"
                          />
                        </TableRow>

                        {building.listings.map((listing, listingIndex) => (
                          <TableRow key={listing.id} data-row-id={listing.id}>
                            {listing.values.map((cell, cellIndex) => {
                              const listingCount = building.listings.length

                              const header = spaceColumns[cellIndex]
                              const DisplayCell =
                                cellComponentMap[header.fieldDataType.id] ??
                                StringCell

                              const isSpaceNotes =
                                header.displayName === 'Space Notes'

                              return (
                                <TableCell
                                  key={`${header.displayName}-${listing.id}`}
                                  data-value-id={`${header.displayName}-${listing.id}`}
                                  className={clsx(
                                    'w-[100px] py-3 px-4 h-20 bg-white',
                                    listingIndex === listingCount - 1 &&
                                      'border-b',
                                    'border-0 border-solid border-[#E0E0E0] border-l border-t',
                                    'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
                                    listingIndex === 0 &&
                                      'first:rounded-tl last:rounded-tr',
                                    isSpaceNotes && 'max-w-[344px]',
                                    cellIndex === 1 && 'border-l-0'
                                  )}
                                >
                                  <div className="max-h-14">
                                    <DisplayCell
                                      cell={cell || {}}
                                      header={header}
                                      onChange={(_, value) => {
                                        onValueChange({
                                          entityId: listing.id,
                                          header,
                                          cell,
                                          value,
                                        })
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell
                            className={clsx(
                              'w-[100px] py-3 px-4 h-20',
                              'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                              'sticky left-8 z-[1] bg-white',
                              !building.listings.length &&
                                'border-t border-dotted #E0E0E0'
                            )}
                          >
                            <ButtonBase
                              disableRipple
                              onClick={() => {
                                onAddSpace(building.surveyBuilding.id)
                              }}
                            >
                              <Typography
                                variant="boldText"
                                color="primary"
                                className="flex items-center gap-[18px]"
                              >
                                <PlusIcon />
                                Add Space
                              </Typography>
                            </ButtonBase>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                              !building.listings.length &&
                                'border-t border-dotted #E0E0E0'
                            )}
                            colSpan={spaceColumns.length - 1}
                          />
                        </TableRow>
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>

      <FieldOrderDrawer
        open={modalName === 'fieldOrder'}
        onClose={() => setModalName(null)}
        fields={spaceColumns}
      />
    </>
  )
}
