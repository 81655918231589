import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonBase,
  TablePagination,
} from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import clsx from 'clsx'

import { Button, Typography, SquareIconButton } from '~/legacy/components'
import { PlusIcon, ReorderIcon, BuildingIcon } from '~/legacy/components/svgs'
import { AddBuildingToSurveyModal2 } from '~/legacy/components/modalComponents/AddBuildingToSurveyModal2'
import { ToggleFiltersButton } from '~/legacy/components/search-bar/search-controls'
import { SearchBarDrawer } from '~/legacy/components/SearchBar'

import {
  cellComponentMap,
  AddFieldModal,
  FieldOrderDrawer,
  TableHeaderCell,
  StringCell,
} from './table-components'
import { useColumnStats } from './hooks/useColumnStats'

export function BuildingsTab({
  buildingColumns,
  surveyBuildings,
  buildingValues,
  onValueChange,
  onAddField,
  onRefreshValues,
  showFilters,
  setShowFilters,
  loading,
  isSticky,
}) {
  const { id } = useParams()
  const [modalName, setModalName] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const hasBuildings = (surveyBuildings?.length || 0) > 0

  const buildingCountString =
    (surveyBuildings || []).length === 1
      ? '1 Building'
      : `${(surveyBuildings || []).length} Buildings`

  const valuesByBuilding = groupBy(buildingValues, 'building_id')

  const nextOrder = useMemo(() => {
    const customFields = buildingColumns.filter((column) => !column.reserved)
    if (customFields.length > 0) {
      const lastOrder = customFields[customFields.length - 1].field.order
      return lastOrder ? lastOrder + 1 : undefined
    }
    return undefined
  }, [buildingColumns])

  const data = (surveyBuildings || []).map(({ building }) => {
    const values = []

    buildingColumns.forEach((column) => {
      const addressValue =
        column.reserved && column.modelName === 'address'
          ? {
              address: building.address,
              city: building.city,
              state: building.state,
              zip: building.zipcode,
              latitude: building.latitude,
              longitude: building.longitude,
            }
          : null

      const modelValue = column.reserved
        ? addressValue || building[column.modelName]
        : null
      const customFieldValue = !column.reserved
        ? valuesByBuilding[building.id]?.find(
            (item) => item.custom_field.id === column.field.id
          )
        : null

      values.push({
        id: column.reserved ? building.id : customFieldValue?.id,
        surveyId: id,
        value: column.reserved ? modelValue : customFieldValue?.value,
      })
    })

    return {
      ...building,
      values,
    }
  })

  const paginatedData = useMemo(() => {
    const buildings = data || []
    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return buildings.slice(startIndex, endIndex)
  }, [data, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const HeaderButtons = () => {
    return (
      <>
        <SquareIconButton
          tooltipText="Field Order"
          onClick={() => setModalName('fieldOrder')}
          style={{ marginRight: '8px' }}
          disabled={!hasBuildings}
          showAsDisabled={!hasBuildings}
        >
          <ReorderIcon />
        </SquareIconButton>
        <Button
          color="primary"
          className="py-0 px-4 h-9 m-auto mr-[1px]"
          onClick={() => setModalName('addBuilding')}
          shrinkButton
        >
          Add Building
        </Button>

        <Button
          color="primary"
          className="py-0 px-4 h-9 m-auto mr-[1px]"
          onClick={() => setModalName('addField')}
          shrinkButton
          disabled={!hasBuildings}
        >
          Add Field
        </Button>
      </>
    )
  }
  const TableControls = ({ modals }) => {
    return (
      <>
        <div
          className={clsx(
            'flex justify-between bg-white px-8 py-4 w-full',
            isSticky && 'sticky top-[54px] z-[2]'
          )}
        >
          <div className="flex items-center justify-center gap-2">
            <Typography
              className="my-auto mr-4 leading-7 font-semibold"
              variant="boldText"
            >
              {buildingCountString}
            </Typography>
            <ToggleFiltersButton
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          </div>

          <div className="flex items-center gap-2">
            <HeaderButtons />
          </div>
        </div>

        {modals}

        <AddBuildingToSurveyModal2
          surveyId={id}
          open={modalName === 'addBuilding'}
          onClose={() => setModalName(null)}
          onSuccess={() => {
            document
              .querySelector('[data-id="add-building-row"]')
              .scrollIntoView({
                behavior: 'smooth',
              })
          }}
        />

        <AddFieldModal
          type="building"
          open={modalName === 'addField'}
          onClose={() => setModalName(null)}
          nextOrder={nextOrder}
          onAddField={onAddField}
        />
      </>
    )
  }

  const NoResults = () => {
    return (
      <>
        <div className="px-8 grow">
          <div className="flex flex-col items-center h-full bg-[#F3F3F3] rounded text-[#666]">
            <div className="relative top-[37%] text-center">
              <BuildingIcon />
              <Typography variant="emptyStateHeader" className="mt-2 mb-1.5">
                Add a building
              </Typography>

              <Typography className="w-[264px] text-center mb-[8.5px]">
                Begin creating your survey or tour book by adding a building.
              </Typography>

              <ButtonBase
                disableRipple
                onClick={() => setModalName('addBuilding')}
              >
                <Typography
                  variant="boldText"
                  color="primary"
                  className="font-semibold leading-[22px]"
                >
                  Add Building
                </Typography>
              </ButtonBase>
            </div>
          </div>
        </div>
      </>
    )
  }

  const columnStats = useColumnStats({
    columns: buildingColumns,
    isNested: false,
  })

  return (
    <>
      <div className="flex justify-between bg-white sticky top-[54px] left-0 w-[98vw] z-[2]">
        <TableControls modals={null} />
      </div>

      <div className="flex flex-row grow">
        {showFilters && <SearchBarDrawer columnStats={columnStats} />}
        {hasBuildings || loading ? (
          <div className="w-full ml-8">
            <div className="absolute top-[133px] left-0 bottom-4 w-8 bg-white z-10" />

            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <TableContainer className="flex-none overflow-visible w-full h-full">
                  <Table size="small" className="border-separate pr-8">
                    <TableHead className="sticky top-[133px] z-[3]">
                      <TableRow>
                        {buildingColumns.map((header, arrayIndex) => {
                          return (
                            <TableHeaderCell
                              key={header.field?.id || header.displayName}
                              allColumns={buildingColumns}
                              header={header}
                              index={arrayIndex}
                              onRefresh={onRefreshValues}
                            />
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map((building, rowIndex) => {
                        const rowCount = data.length

                        return (
                          <TableRow key={building.id} data-row-id={building.id}>
                            {building.values.map((cell, cellIndex) => {
                              const header = buildingColumns[cellIndex]
                              const DisplayCell =
                                cellComponentMap[header.fieldDataType.id] ??
                                StringCell

                              const isDescription =
                                header.displayName === 'Building Notes'

                              const isAddress = header.displayName === 'Address'

                              return (
                                <TableCell
                                  key={`${header.displayName}-${building.id}`}
                                  data-value-id={`${header.displayName}-${building.id}`}
                                  className={clsx(
                                    'w-[100px] py-3 px-4 h-20 bg-white',
                                    'border-0 border-solid border-[#E0E0E0] border-l',
                                    'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
                                    rowIndex !== 0 && 'border-t',
                                    rowIndex === rowCount - 1 && 'border-b',
                                    isDescription && 'max-w-[344px]',
                                    isAddress && 'min-w-[260px] text-left',
                                    cellIndex === 1 && 'border-l-0'
                                  )}
                                >
                                  <div className="flex max-h-14 items-center">
                                    <DisplayCell
                                      cell={cell || {}}
                                      header={header}
                                      onChange={(_, value) => {
                                        onValueChange({
                                          entityId: building.id,
                                          header,
                                          cell,
                                          value,
                                        })
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}

                      <TableRow data-id="add-building-row">
                        <TableCell
                          className={clsx(
                            'w-[100px] py-3 px-4 h-20',
                            'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                            'sticky left-8 z-[1] bg-white'
                          )}
                        >
                          <ButtonBase
                            disableRipple
                            onClick={() => setModalName('addBuilding')}
                          >
                            <Typography
                              variant="boldText"
                              color="primary"
                              className="flex items-center gap-[18px]"
                            >
                              <PlusIcon />
                              Add Building
                            </Typography>
                          </ButtonBase>
                        </TableCell>
                        <TableCell
                          className="border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br"
                          colSpan={buildingColumns.length - 1}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="sticky bottom-0 bg-white z-[2] w-[95vw] max-w-[800px]">
                  <TablePagination
                    component="div"
                    count={surveyBuildings?.length || 0}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[50, 100]}
                    className="sticky bottom-0 bg-white z-[2] w-[95vw] max-w-[800px]"
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <NoResults />
        )}
      </div>

      <FieldOrderDrawer
        open={modalName === 'fieldOrder'}
        fields={buildingColumns}
        onClose={() => setModalName(null)}
      />
    </>
  )
}
