import React from 'react'
import { useCollectionContext } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/collection-context'
import { FilterLabel, ApplyFiltersButton, ClearFiltersButton } from './search-bar/search-controls'
import {
  CustomFieldInput,
  StandardInput,
  // LatLngInput,
} from '~/legacy/components/search-bar/custom-field-controls'
import { Typography } from '~/legacy/components'
import { useSearchQueryState } from '~/legacy/components/search-bar/search-context'

export default function SearchBar({ isLoading: isUpdating = false, columnStats }) {
  const {
    buildingCustomFields,
    listingCustomFields,
    isLoading: isLoadingFields,
  } = useCollectionContext()

  const {
    filters,
    setFilters,
    customFieldFilters,
    setCustomFieldFilters,
    sortColumn,
    sortOrder,
  } = useSearchQueryState()

  const allCustomFields = [...buildingCustomFields, ...listingCustomFields]
  const sizeFields = allCustomFields.filter((field) => field.data_type === 5) // SIZE_SQFT type
  const otherCustomFields = allCustomFields.filter(
    (field) => field.data_type !== 5
  )

  const handleCustomFieldChange = (fieldId, value) => {
    setCustomFieldFilters({
      ...customFieldFilters,
      [fieldId]: value,
    })
  }

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  if (isLoadingFields) {
    return <div>Loading filters...</div>
  }

  return (
    <div className="space-y-6">
      <div className="flex gap-2">
        <ApplyFiltersButton
          isLoading={isUpdating}
          className="bg-white hover:bg-gray-50"
        />
        <ClearFiltersButton />
      </div>
      {/* Standard Building Filters */}
      <div className="space-y-4">
        <div className="space-y-3">
          <FilterLabel
            label={`Full Address Search (${
              columnStats?.['Full Address']?.distinctCount ?? 0
            } unique)`}
          >
            <StandardInput
              value={filters.fullAddressMatches}
              onChange={(value) =>
                handleFilterChange('fullAddressMatches', value)
              }
              placeholder="Search across all address fields..."
            />
          </FilterLabel>

          {/* <FilterLabel label="Radius Search">
            <div className="space-y-2">
              <button
                type="button"
                onClick={() =>
                  handleFilterChange('showLatLngInput', !filters.showLatLngInput)
                }
                className="px-2 py-1 text-sm border rounded hover:bg-gray-50"
                title={
                  filters.showLatLngInput
                    ? 'Switch to address input'
                    : 'Switch to coordinates input'
                }
              >
                {filters.showLatLngInput ? 'ABC' : 'LAT/LNG'}
              </button>

              <div className="flex gap-2">
                {filters.showLatLngInput ? (
                  <LatLngInput
                    lat={filters.searchRadiusLat}
                    lng={filters.searchRadiusLng}
                    onLatChange={(value) =>
                      handleFilterChange('searchRadiusLat', value)
                    }
                    onLngChange={(value) =>
                      handleFilterChange('searchRadiusLng', value)
                    }
                  />
                ) : (
                  <div className="flex-1">
                    <StandardInput
                      value={filters.searchRadiusGeocodable}
                      onChange={(value) => {
                        handleFilterChange('searchRadiusGeocodable', value)
                        // Clear lat/lng when address changes
                        if (!value) {
                          handleFilterChange('searchRadiusLat', null)
                          handleFilterChange('searchRadiusLng', null)
                        }
                      }}
                      placeholder="Center location..."
                    />
                  </div>
                )}

                <div className="w-16">
                  <StandardInput
                    type="number"
                    value={filters.searchRadiusMiles}
                    onChange={(value) =>
                      handleFilterChange('searchRadiusMiles', value)
                    }
                    placeholder="Miles"
                  />
                </div>
              </div>
            </div>
          </FilterLabel> */}

          <FilterLabel
            label={`Building Name (${
              columnStats?.['Building Name']?.distinctCount ?? 0
            } unique)`}
          >
            <StandardInput
              value={filters.buildingName}
              onChange={(value) => handleFilterChange('buildingName', value)}
              placeholder="Search building name..."
            />
          </FilterLabel>

          {/* Size Fields */}
          {sizeFields.length > 0 && (
            <div className="space-y-3">
              {sizeFields.map((field) => (
                <FilterLabel
                  key={field.id}
                  label={`${field.label} (${columnStats?.[field.label]?.distinctCount ?? 0} unique)`}
                >
                  <div className="min-w-0">
                    <CustomFieldInput
                      field={field}
                      value={customFieldFilters[field.id]}
                      onChange={(value) => handleCustomFieldChange(field.id, value)}
                      columnStats={{
                        ...columnStats?.[field.label],
                        distinctValues: columnStats?.[field.label]?.fullDistinctValues ?? new Set(),
                        distinctCount: columnStats?.[field.label]?.fullDistinctCount ?? 0,
                      }}
                    />
                  </div>
                </FilterLabel>
              ))}
            </div>
          )}

          <FilterLabel label="Survey Created Date">
            <div className="min-w-0">
              <CustomFieldInput
                field={{ data_type: 7 }} // 7 is the date type
                value={`${filters.createdAfter || ''}-${
                  filters.createdBefore || ''
                }`}
                onChange={(value) => {
                  const [after, before] = (value || '').split('-')
                  handleFilterChange('createdAfter', after || null)
                  handleFilterChange('createdBefore', before || null)
                }}
              />
            </div>
          </FilterLabel>
        </div>
      </div>

      {/* Custom Field Filters */}
      {otherCustomFields.length > 0 && (
        <div className="space-y-4">
          <div className="space-y-3">
            {otherCustomFields.map((field) => (
              <FilterLabel
                key={field.id}
                label={`${field.label} (${columnStats?.[field.id]?.distinctCount ?? 0} unique)`}
              >
                <div className="min-w-0">
                  <CustomFieldInput
                    field={field}
                    value={customFieldFilters[field.id]}
                    onChange={(value) => handleCustomFieldChange(field.id, value)}
                    columnStats={{
                      ...columnStats?.[field.id],
                      distinctValues: columnStats?.[field.id]?.fullDistinctValues ?? new Set(),
                      distinctCount: columnStats?.[field.id]?.fullDistinctCount ?? 0,
                    }}
                  />
                </div>
              </FilterLabel>
            ))}
          </div>
        </div>
      )}

      <pre>
        {JSON.stringify(
          {
            filters,
            customFieldFilters,
            sortColumn: sortColumn || 'none',
            sortOrder: sortOrder || 'none',
          },
          null,
          2
        )}
      </pre>
    </div>
  )
}

export const SearchBarDrawer = ({ columnStats }) => {
  return (
    <div className="sticky top-[133px] h-[calc(100vh-133px)] overflow-auto w-[300px] flex-shrink-0 border-r border-[#E0E0E0] p-4 pl-12">
      <Typography variant="h3" className="mb-4">
        Filters
      </Typography>
      <SearchBar columnStats={columnStats} />
    </div>
  )
}
