import React from 'react'
import { FilterList } from '@material-ui/icons'
import { useSearchQueryState } from './search-context'

export const FilterHeader = ({ children }) => (
  <h3 className="font-medium text-lg">{children}</h3>
)

export const FilterLabel = ({ label, children }) => (
  <div className="space-y-4">
    <div className="flex flex-wrap gap-2">
      <label className="block text-sm mb-1">{label}</label>
      {children}
    </div>
  </div>
)

export const SearchButton = ({ column, children }) => {
  const { setSortColumn, setSortOrder, sortColumn, sortOrder, refresh } = useSearchQueryState()
  const isSelected = (sortColumn === column) && column !== null
  const direction = isSelected ? sortOrder : 'asc'

  return (
    <div
      // eslint-disable-next-line no-nested-ternary
      onClick={() => {
        console.log('setting sort', column, direction)
        const columnToSort = column || sortColumn 
        const newDirection = direction === 'asc' ? 'desc' : 'asc'
        const order = isSelected ? newDirection : 'asc'
        setSortColumn(columnToSort)
        setSortOrder(order)
        refresh({ sortColumn: columnToSort, sortOrder: order })
      }}
      className={`${
        isSelected ? 'bg-blue-100' : ''
      } no-wrap w-full flex flex-row`}
    >
      {children}
      {isSelected && (direction === 'asc' ? ' ↑' : ' ↓')}
    </div>
  )
}

export const ApplyFiltersButton = ({ isLoading = false, className = '' }) => {
  const { refresh } = useSearchQueryState()
  const isDisabled = isLoading

  return (
    <div className="flex">
      <button
        type="button"
        disabled={isDisabled}
        onClick={() => {
          refresh()
        }}
        className={`px-4 py-2 border rounded ${className}`}
      >
        {isLoading ? 'Applying...' : 'Apply'}
      </button>
    </div>
  )
}

export const ClearFiltersButton = () => {
  const { clearFilters } = useSearchQueryState()

  return (
    <button
      type="button"
      onClick={() => {
        clearFilters()
      }}
      className="px-4 py-2 border rounded bg-white hover:bg-gray-50"
    >
    Clear
    </button>
  )
}

export const ToggleFiltersButton = ({ showFilters, setShowFilters }) => {
  const { activeFilterCount } = useSearchQueryState()
  const filterCountCopy =
    activeFilterCount > 0 ? (
      <>
        {activeFilterCount}
        {' '}
Filter
        {activeFilterCount > 1 ? 's' : ''}
      </>
    ) : (
      `Filters`
    )
  return (
    <div className="px-4 flex items-center">
      <button
        color="secondary"
        className="py-0 px-4 h-9 border border-black text-black mb-0 rounded bg-white flex items-center"
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterList />
        <div className="leading-9">
          {showFilters ? 'Hide Filters' : filterCountCopy}
        </div>
      </button>
    </div>
  )
}
