import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import {
  Container,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core'

import { useCollectionContext } from './collection-context'
import { Breadcrumbs, Typography } from '~/legacy/components'
import { isBroker } from '~/legacy/utils'
import { BuildingsTab } from './buildings-tab'
import { SpacesTab } from './spaces-tab'

export function BulkEditCollection() {
  const {
    survey,
    loading,
    surveyId,
    databaseId,
    buildingValues,
    listingValues,
    buildingColumns,
    spaceColumns,
    handleBuildingValueChange,
    handleListingValueChange,
    refreshBuildingValues,
    refreshListingValues,
    createCustomField,
    handleAddSpace,
  } = useCollectionContext()

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const selectedTab = queryParams.get('tab') || 'buildings'

  const user = useSelector((store) => store.user)

  const stickyTabsRef = React.createRef()
  const mainContainerRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const surveyBuildingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )

  const surveyListingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )

  const hasBuildings = (surveyBuildingsRedux?.length || 0) > 0

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tab = queryParams.get('tab')
    
    // Only scroll to top when changing tabs
    if (tab && mainContainerRef.current) {
      mainContainerRef.current.scrollTo(0, 0)
    }
  }, [location.search])

  useEffect(() => {
    const cachedRef = stickyTabsRef.current
    if (!cachedRef) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)

    return () => observer.unobserve(cachedRef)
  }, [stickyTabsRef])

  const project = survey?.project
  const company = survey?.company

  /**
   *
   * @async
   * @param {Object} payload
   * @param {string} payload.label
   * @param {number} payload.order
   * @param {('building'|'space')} payload.type
   */
  const handleAddField = async ({ label, order, type }) => {
    const uniqueFieldNames = new Set(
      (type === 'building' ? buildingColumns : spaceColumns).map((item) =>
        item.displayName.toLowerCase()
      )
    )

    if (uniqueFieldNames.has(label.toLowerCase())) {
      return { error: `You already have a field named ${label}`, data: null }
    }

    const data = await createCustomField({
      label,
      order,
      isBuildingField: type === 'building',
      optimistic: false,
    })

    return { error: null, data }
  }

  const breadcrumbs = [
    company?.name
      ? {
          name: company.name,
          path: '/',
        }
      : null,
    project?.id
      ? {
          name: project.name,
          path: `/projects/${project.id}`,
          replace: true,
        }
      : {
          name: 'Surveys',
          path: isBroker(user) ? '/surveys' : '/dashboard',
        },
    {
      name: survey?.name,
      path: surveyId ? `/surveys/view/${surveyId}` : `/databases/view/${databaseId}`,
      replace: true,
    },
    { name: 'Bulk Edit' },
  ].filter(Boolean)

  return (
    <Container
      maxWidth={false}
      classes={{
        root: 'flex flex-col p-0 pb-8 max-w-full w-full overflow-auto',
      }}
      ref={mainContainerRef}
    >
      <div className="w-full mx-auto px-8 bg-white sticky top-0 left-0 z-[2]">
        <div className="w-full mx-auto mt-3 flex items-center min-h-11">
          <Breadcrumbs pieces={breadcrumbs} />
        </div>
        <div className="pt-6 px-0 pb-4">
          <Typography variant="h1">Bulk Edit</Typography>
        </div>
      </div>

      <div
        className={clsx(
          'sticky px-8 top-[-1px] left-0 z-[3] bg-white h-[54px]',
          isSticky &&
            'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:bg-[#E0E0E0] after:z-[-1]'
        )}
        ref={stickyTabsRef}
      >
        {/* FIXME: This is a hack to avoid showing the table content behing the sticky header */}
        <div
          className={clsx(
            'absolute h-4 bg-white left-8 right-0 top-[54px] z-[-1]',
            isSticky ? 'block' : 'hidden'
          )}
        />

        <Tabs
          className="h-full shadow-border"
          classes={{ flexContainer: 'h-[54px]' }}
          value={selectedTab}
          onChange={(_, value) => {
            const searchParams = new URLSearchParams(location.search)
            searchParams.set('tab', value)
            history.replace({
              pathname: location.pathname,
              search: searchParams.toString(),
            })
          }}
          indicatorColor="primary"
        >
          <Tab
            className="min-w-[unset] opacity-100"
            value="buildings"
            label="Buildings"
          />

          {hasBuildings ? (
            <Tab
              className="min-w-[unset] opacity-100"
              value="spaces"
              label="Spaces"
              disabled={!hasBuildings}
            />
          ) : (
            <Tooltip
              title={!hasBuildings ? 'Add a building first' : ''}
              disableFocusListener
              disableTouchListener
              classes={{
                tooltipPlacementBottom: 'my-2 mx-0',
              }}
            >
              <span>
                <Tab
                  className="min-w-[unset] opacity-100 h-[54px]"
                  value="spaces"
                  label="Spaces"
                  disabled={!hasBuildings}
                />
              </span>
            </Tooltip>
          )}
        </Tabs>
      </div>

      <div className="flex">
        {selectedTab === 'buildings' ? (
          <div className="flex flex-grow">
            <div className="flex-grow">
              <BuildingsTab
                buildingColumns={buildingColumns}
                buildingValues={buildingValues}
                surveyBuildings={surveyBuildingsRedux}
                onValueChange={handleBuildingValueChange}
                onAddField={handleAddField}
                onRefreshValues={refreshBuildingValues}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                loading={loading}
                isSticky={isSticky}
              />
            </div>
          </div>
        ) : null}

        {selectedTab === 'spaces' ? (
          <div className="flex-grow">
            <SpacesTab
              spaceColumns={spaceColumns}
              surveyBuildings={surveyBuildingsRedux}
              surveyListings={surveyListingsRedux}
              listingValues={listingValues}
              onValueChange={handleListingValueChange}
              onAddSpace={handleAddSpace}
              onAddField={handleAddField}
              onRefreshValues={refreshListingValues}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              loading={loading}
              isSticky={isSticky}
            />
          </div>
        ) : null}
      </div>
    </Container>
  )
}
