// @ts-check
import {
  ButtonBase,
  Container,
  Dialog,
  Divider,
  Drawer,
  Fade,
  InputAdornment,
  Menu,
  MenuItem,
  Slide,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  useTheme,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import React, {
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
  useCallback,
} from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { useParams, useLocation, useHistory } from 'react-router'
import { format } from 'date-fns'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import {
  Breadcrumbs,
  ConfirmModalComponent,
  FieldDataTypeMenuSection,
  InlineEditableValue,
  Loading,
  SquareIconButton,
  Typography,
  File,
  MODALS,
  Modal,
} from '~/legacy/components'
import {
  setSurveyBuildings,
  setSurveyListings,
} from '~/legacy/store/actions/viewSurvey'
import {
  isBroker,
  useSurveyBuildingCustomFieldSelector,
  SnackbarUtils,
  arrayMove,
  BACKEND_FOR_DND,
  getViewBuildingRoute,
  getBuildingPrimaryName,
  getListingPrimaryName,
} from '~/legacy/utils'
import {
  BULK_IMPORT_CONSTANTS,
  getDataTypeByDisplayName,
  BULK_IMPORT_HELPERS,
  getModelFieldNameByDisplayName,
} from '~/legacy/utils/bulkImportUtils'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import {
  EditableDateCell,
  EditableMultilineStringCell,
  EditableNumberCell,
  EditableStringCell,
} from '~/legacy/components/tableComponents'
import { buildingApi, listingApi, surveyBuildingApi } from '~/legacy/fetchApi'
import { LEASE_DASHBOARD, LEASE_LOGIN, LEASE_PROJECTS } from '~/legacy/consts'
import {
  CloseIcon,
  DeleteIcon,
  LockIcon,
  LogoIcon,
  MenuSelectedItemIcon,
  PlusIcon,
  TableClearIcon,
  TextChevronDown,
  ReorderIcon,
  DragIcon,
  BuildingIcon,
} from '~/legacy/components/svgs'
import { AddBuildingToSurveyModal2 } from '~/legacy/components/modalComponents/AddBuildingToSurveyModal2'
import Button from '~/legacy/components/forms/Button'
import { DeleteSpaceFieldModal as DeleteFieldModal } from '~/legacy/components/modalComponents/DeleteSpaceFieldModal'
import { ActionModal2 } from '~/legacy/components/modals/ActionModal2'
import { useTranslation } from '~/locales/translations'
import { IMAGE_UPLOAD_MODAL_TAB_KEYS } from '~/legacy/components/modals'

const BUILDING_RESERVED_FIELDS = [
  'Address',
  'Building Name',
  'Building Notes',
  'Amenities',
]
const SPACE_RESERVED_FIELDS = ['Space Name', 'Space Notes']

export default function BulkEditSurvey() {
  const { id: surveyId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const selectedTab = queryParams.get('tab') || 'buildings'

  const [isSurveyListingsLoading, setIsSurveyListingsLoading] = useState(false)
  const handleError = useErrorHandler()
  const dispatch = useDispatch()
  const user = useSelector((store) => store.user)

  const stickyTabsRef = React.createRef()
  const mainContainerRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)

  const surveyBuildingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )

  const surveyListingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )

  const hasBuildings = surveyBuildingsRedux.length > 0

  useEffect(() => {
    const cachedRef = stickyTabsRef.current
    if (!cachedRef) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)

    return () => observer.unobserve(cachedRef)
  }, [stickyTabsRef])

  // FIXME: See if I can move this to use SWR instead of Redux
  // Also see if we can avoid using `redirectNoPermissionUser` that was copied from the BrokerSurvey
  useEffect(() => {
    // Ensure only one redirect goes off. Multiple messes with URL
    let redirected = false
    const redirectNoPermissionUser = () => {
      if (!redirected) {
        redirected = true
        let redirectUrl = ''
        if (user && user.loggedIn) {
          redirectUrl = isBroker(user) ? LEASE_PROJECTS : LEASE_DASHBOARD
        } else {
          // User an existing redirect param if one exists
          let redirect = ''
          const existingRedirect = queryParams.get('redirect')
          if (existingRedirect) {
            redirect = existingRedirect
          } else {
            redirect = `${encodeURIComponent(
              location.pathname
            )}${encodeURIComponent(location.search || '')}`
          }
          redirectUrl = `${LEASE_LOGIN}?redirect=${redirect}`
        }
        history.push(redirectUrl)
      }
    }

    const fetchSurveyListings = (redirectNoPermissionUser) => {
      setIsSurveyListingsLoading(true)
      return Api.getSurveyListings({ id: surveyId }).then((results) => {
        if (results && isBadAuthResponseMessage(results.detail)) {
          redirectNoPermissionUser()
        } else if (!results) {
          handleError(new Error('Error loading survey listings.'))
        } else {
          dispatch(setSurveyListings(results.survey_listings))
          dispatch(setSurveyBuildings(results.survey_buildings))
          setIsSurveyListingsLoading(false)
        }
      })
    }

    dispatch(setSurveyListings([]))
    fetchSurveyListings(redirectNoPermissionUser)
  }, [surveyId, dispatch, handleError])

  // Make sure we reset the scroll when changing tabs
  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo(0, 0)
    }
  }, [location.search])

  const {
    survey,
    buildingCustomFields,
    listingCustomFields,
    isLoading: isCustomFieldsLoading,
    mutateCreateCustomField: createCustomField,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  const {
    customFieldValues: buildingValues,
    isLoading: isBuildingValuesLoading,
    mutate: refreshBuildingValues,
    mutateChangeBuildingCustomFieldValue: updateBuildingCustomField,
    mutateCreateBuildingCustomFieldValue: createBuildingCustomField,
  } = useSurveyBuildingCustomFieldSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const {
    customFieldValues: listingValues,
    isLoading: isListingValuesLoading,
    mutate: refreshListingValues,
    mutateChangeListingCustomFieldValue: updateListingCustomField,
    mutateCreateListingCustomFieldValue: createListingCustomField,
  } = useSurveyListingCustomFieldSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const project = survey?.project
  const company = survey?.company

  const buildingColumns = useMemo(() => {
    const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING
    const totalFields =
      BUILDING_RESERVED_FIELDS.length + buildingCustomFields.length

    const allFields = BUILDING_RESERVED_FIELDS.map((field, index) => {
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      const isAmenities = modelName === 'amenities'

      return {
        displayName: field,
        // Put amenities as the last column
        index: isAmenities ? totalFields - 1 : index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
        icon: LogoIcon,
      }
    }).concat(
      buildingCustomFields.map((field, index) => {
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + BUILDING_RESERVED_FIELDS.length - 1

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
          icon: fieldDataType.icon,
        }
      })
    )

    return allFields.sort((a, b) => a.index - b.index)
  }, [buildingCustomFields])

  const spaceColumns = useMemo(() => {
    return SPACE_RESERVED_FIELDS.map((field, index) => {
      const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
      const fieldDataType = getDataTypeByDisplayName(field)
      const modelName = getModelFieldNameByDisplayName(field)

      return {
        displayName: field,
        index,
        fieldType,
        fieldDataType,
        reserved: true,
        modelName,
        icon: LogoIcon,
      }
    }).concat(
      listingCustomFields.map((field, index) => {
        const fieldType = BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
        const fieldDataType = BULK_IMPORT_HELPERS.getFieldDataTypeById(
          field.data_type
        )
        const newIndex = index + SPACE_RESERVED_FIELDS.length

        return {
          displayName: field.label,
          index: newIndex,
          fieldType,
          fieldDataType,
          reserved: false,
          field,
          modelName: null,
          icon: fieldDataType.icon,
        }
      })
    )
  }, [listingCustomFields])

  const handleBuildingValueChange = ({
    entityId: buildingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createBuildingCustomField({
          surveyId,
          buildingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateBuildingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      if (header.modelName === 'address') {
        const addressValues = value.GOOGLE_ADDRESS

        // Copying the buildings to prevent the address being updated even when the request fails
        // I think this happens because `Api.updateBuildingAddress` already handles some redux state by itself
        const beforeEditBuildings = [...surveyBuildingsRedux]

        if (addressValues.address) {
          Api.updateBuildingAddress({
            id: buildingId,
            body: {
              ...addressValues,
            },
          }).then((res) => {
            const { error } = res.data

            if (error) {
              SnackbarUtils.error(error)
              dispatch(setSurveyBuildings(beforeEditBuildings))
            } else {
              const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
                if (sb.building.id === buildingId) {
                  return {
                    ...sb,
                    building: {
                      ...sb.building,
                      ...res.data,
                    },
                  }
                }
                return { ...sb }
              })
              dispatch(setSurveyBuildings(udpatedBuildings))
            }
          })
        }

        return
      }

      const updatedBuilding = {
        [header.modelName]: value,
      }

      buildingApi
        .updatePartial({
          buildingId,
          partial: updatedBuilding,
        })
        .then(([, responseBuilding]) => {
          const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
            if (sb.building.id === buildingId) {
              return {
                ...sb,
                building: {
                  ...sb.building,
                  ...responseBuilding,
                },
              }
            }
            return { ...sb }
          })
          dispatch(setSurveyBuildings(udpatedBuildings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyBuildings(surveyBuildingsRedux))
        })
    }
  }

  const handleListingValueChange = ({
    entityId: listingId,
    header,
    cell,
    value,
  }) => {
    const isCustomField = !header.reserved
    const isCreatingNewValue = !cell.id

    if (isCustomField) {
      if (isCreatingNewValue) {
        createListingCustomField({
          surveyId,
          listingId,
          customFieldId: header.field.id,
          newValue: value,
        })
      } else {
        updateListingCustomField({
          id: cell.id,
          newValue: value,
        })
      }
    }

    if (!isCustomField) {
      const updatedListing = {
        [header.modelName]: value,
      }

      listingApi
        .updatePartial({
          listingId,
          partial: updatedListing,
        })
        .then(([, responseListing]) => {
          const newListings = surveyListingsRedux.map((sl) => {
            if (sl.listing.id === listingId) {
              return {
                ...sl,
                listing: {
                  ...sl.listing,
                  ...responseListing,
                },
              }
            }
            return { ...sl }
          })
          dispatch(setSurveyListings(newListings))
        })
        .catch((error) => {
          console.error(error)
          SnackbarUtils.error(`Error updating ${header.displayName}`)
          dispatch(setSurveyListings(surveyListingsRedux))
        })
    }
  }

  const handleAddSpace = (surveyBuildingId) => {
    surveyBuildingApi
      .createSurveyListing({
        surveyBuildingId,
      })
      .then(([, responseSurveyBuilding]) => {
        const { building, survey_listings: responseSurveyListings } =
          responseSurveyBuilding.data
        const surveyListingsIds = surveyListingsRedux.map((sl) => sl.id)

        // Filter the survey listings in the response and keep only the ones that are not already in the table
        // This also handles the scenario of 2 users working on the same survey and adding spaces,
        // for every added space we get the complete list of spaces for the corresponging building
        // so we can catch spaces added by another user.
        // Maybe we need something in the UI to indicate what's the space that was added by the current user
        const listingsToAdd = responseSurveyListings
          .filter((sl) => !surveyListingsIds.includes(sl.id))
          .map((sl) => {
            return {
              ...sl,
              listing: {
                ...sl.listing,
                // We need to add the building in order to make the new row appear in tht table
                // Since we are using `dispatch(setSurveyListings(...))` we need to have the same format
                building,
              },
            }
          })

        dispatch(setSurveyListings([...surveyListingsRedux, ...listingsToAdd]))
      })
  }

  /**
   *
   * @async
   * @param {Object} payload
   * @param {string} payload.label
   * @param {number} payload.order
   * @param {('building'|'space')} payload.type
   */
  const handleAddField = async ({ label, order, type }) => {
    const uniqueFieldNames = new Set(
      (type === 'building' ? buildingColumns : spaceColumns).map((item) =>
        item.displayName.toLowerCase()
      )
    )

    if (uniqueFieldNames.has(label.toLowerCase())) {
      return { error: `You already have a field named ${label}`, data: null }
    }

    const data = await createCustomField({
      label,
      order,
      isBuildingField: type === 'building',
      optimistic: false,
    })

    return { error: null, data }
  }

  const handleBuildingImagesChange = async ({ buildingId, payload }) => {
    buildingApi
      .updatePartial({
        buildingId,
        partial: payload,
      })
      .then(([, responseBuilding]) => {
        const udpatedBuildings = surveyBuildingsRedux.map((sb) => {
          if (sb.building.id === buildingId) {
            return {
              ...sb,
              building: {
                ...sb.building,
                ...responseBuilding.data,
              },
            }
          }
          return { ...sb }
        })
        dispatch(setSurveyBuildings(udpatedBuildings))
      })
      .catch((error) => {
        console.error(error)
        dispatch(setSurveyBuildings(surveyBuildingsRedux))
      })
  }

  const handleListingImagesChange = async ({ listingId, payload }) => {
    listingApi
      .updatePartial({
        listingId,
        partial: payload,
      })
      .then(([, responseListing]) => {
        const updatedListings = surveyListingsRedux.map((sl) => {
          if (sl.listing.id === listingId) {
            return {
              ...sl,
              listing: {
                ...sl.listing,
                ...responseListing,
              },
            }
          }
          return { ...sl }
        })
        dispatch(setSurveyListings(updatedListings))
      })
      .catch((error) => {
        console.error(error)
        dispatch(setSurveyListings(surveyListingsRedux))
      })
  }

  if (
    isSurveyListingsLoading ||
    isCustomFieldsLoading ||
    isBuildingValuesLoading ||
    isListingValuesLoading
  ) {
    return (
      <div className="mt-[50px]">
        <Loading isLoading size={40} />
      </div>
    )
  }

  const breadcrumbs = [
    company?.name
      ? {
          name: company.name,
          path: '/',
        }
      : null,
    project?.id
      ? {
          name: project.name,
          path: `/projects/${project.id}`,
          replace: true,
        }
      : {
          name: 'Surveys',
          path: isBroker(user) ? '/surveys' : '/dashboard',
        },
    {
      name: survey.name,
      path: `/surveys/view/${surveyId}`,
      replace: true,
    },
    { name: 'Bulk Edit' },
  ].filter(Boolean)

  return (
    <Container
      maxWidth={false}
      classes={{
        root: 'flex flex-col p-0 pb-8 max-w-full w-full overflow-auto',
      }}
      ref={mainContainerRef}
    >
      <div className="w-full mx-auto px-8 bg-white sticky left-0 z-[1]">
        <div className="w-full mx-auto mt-3 flex items-center min-h-11">
          <Breadcrumbs pieces={breadcrumbs} />
        </div>
        <div className="pt-6 px-0 pb-4">
          <Typography variant="h1">Bulk Edit</Typography>
        </div>
      </div>

      <div
        className={clsx(
          'sticky px-8 top-[-1px] left-0 z-[3] bg-white h-[54px]',
          isSticky &&
            'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:bg-[#E0E0E0] after:z-[-1]'
        )}
        ref={stickyTabsRef}
      >
        {/* FIXME: This is a hack to avoid showing the table content behing the sticky header */}
        <div
          className={clsx(
            'absolute h-4 bg-white left-8 right-0 top-[54px] z-[-1]',
            isSticky ? 'block' : 'hidden'
          )}
        />

        <Tabs
          className="h-full shadow-border"
          classes={{ flexContainer: 'h-[54px]' }}
          value={selectedTab}
          onChange={(_, value) =>
            history.replace({
              pathname: location.pathname,
              search: `?tab=${value}`,
            })
          }
          indicatorColor="primary"
        >
          <Tab
            className="min-w-[unset] opacity-100"
            value="buildings"
            label="Buildings"
          />

          {hasBuildings ? (
            <Tab
              className="min-w-[unset] opacity-100"
              value="spaces"
              label="Spaces"
              disabled={!hasBuildings}
            />
          ) : (
            <Tooltip
              title={!hasBuildings ? 'Add a building first' : ''}
              disableFocusListener
              disableTouchListener
              classes={{
                tooltipPlacementBottom: 'my-2 mx-0',
              }}
            >
              <span>
                <Tab
                  className="min-w-[unset] opacity-100 h-[54px]"
                  value="spaces"
                  label="Spaces"
                  disabled={!hasBuildings}
                />
              </span>
            </Tooltip>
          )}
        </Tabs>
      </div>

      {selectedTab === 'buildings' ? (
        <BuildingsTab
          buildingColumns={buildingColumns}
          buildingValues={buildingValues}
          surveyBuildings={surveyBuildingsRedux}
          onValueChange={handleBuildingValueChange}
          onAddField={handleAddField}
          onRefreshValues={refreshBuildingValues}
          onImagesChange={handleBuildingImagesChange}
        />
      ) : null}

      {selectedTab === 'spaces' ? (
        <SpacesTab
          spaceColumns={spaceColumns}
          surveyBuildings={surveyBuildingsRedux}
          surveyListings={surveyListingsRedux}
          listingValues={listingValues}
          onValueChange={handleListingValueChange}
          onAddSpace={handleAddSpace}
          onAddField={handleAddField}
          onRefreshValues={refreshListingValues}
          onImagesChange={handleListingImagesChange}
        />
      ) : null}
    </Container>
  )
}

function BuildingsTab({
  buildingColumns,
  surveyBuildings,
  buildingValues,
  onValueChange,
  onAddField,
  onRefreshValues,
  onImagesChange,
}) {
  const { id: surveyId } = useParams()
  const theme = useTheme()
  const [modalName, setModalName] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [currentActionBuilding, setCurrentActionBuilding] = useState(null)

  const currentActionBuildingName = useMemo(
    () =>
      currentActionBuilding
        ? getBuildingPrimaryName(currentActionBuilding)
        : '',
    [currentActionBuilding]
  )

  const hasBuildings = surveyBuildings.length > 0

  const buildingCountString =
    surveyBuildings.length === 1
      ? '1 Building'
      : `${surveyBuildings.length} Buildings`

  const valuesByBuilding = groupBy(buildingValues, 'building_id')

  const nextOrder = useMemo(() => {
    const customFields = buildingColumns.filter((column) => !column.reserved)
    if (customFields.length > 0) {
      const lastOrder = customFields[customFields.length - 1].field.order
      return lastOrder ? lastOrder + 1 : undefined
    }

    return undefined
  }, [buildingColumns])

  const data = surveyBuildings.map(({ building }) => {
    const values = []

    buildingColumns.forEach((column) => {
      const addressValue =
        column.reserved && column.modelName === 'address'
          ? {
              address: building.address,
              city: building.city,
              state: building.state,
              zip: building.zipcode,
              latitude: building.latitude,
              longitude: building.longitude,
            }
          : null

      const modelValue = column.reserved
        ? addressValue || building[column.modelName]
        : null
      const customFieldValue = !column.reserved
        ? valuesByBuilding[building.id]?.find(
            (item) => item.custom_field_id === column.field.id
          )
        : null

      values.push({
        id: column.reserved ? building.id : customFieldValue?.id,
        surveyId,
        value: column.reserved ? modelValue : customFieldValue?.value,
      })
    })

    return {
      ...building,
      values,
    }
  })

  return (
    <>
      <div className="flex justify-between bg-white px-8 py-4 sticky left-0 z-[1]">
        <Typography
          className="my-auto mr-4 leading-7 font-semibold"
          variant="boldText"
        >
          {buildingCountString}
        </Typography>

        <div className="flex items-center gap-2">
          <SquareIconButton
            tooltipText="Field Order"
            onClick={() => setModalName('fieldOrder')}
            style={{ marginRight: '8px' }}
            disabled={!hasBuildings}
            showAsDisabled={!hasBuildings}
          >
            <ReorderIcon />
          </SquareIconButton>
          <Button
            color="primary"
            className="py-0 px-4 h-9 m-auto mr-[1px]"
            onClick={() => setModalName('addBuilding')}
            shrinkButton
          >
            Add Building
          </Button>

          <Button
            color="primary"
            className="py-0 px-4 h-9 m-auto mr-[1px]"
            onClick={() => setModalName('addField')}
            shrinkButton
            disabled={!hasBuildings}
          >
            Add Field
          </Button>
        </div>

        <AddBuildingToSurveyModal2
          surveyId={surveyId}
          open={modalName === 'addBuilding'}
          onClose={() => setModalName(null)}
          onSuccess={() => {
            document
              .querySelector('[data-id="add-building-row"]')
              .scrollIntoView({
                behavior: 'smooth',
              })
          }}
        />

        <AddFieldModal
          type="building"
          open={modalName === 'addField'}
          onClose={() => setModalName(null)}
          nextOrder={nextOrder}
          onAddField={onAddField}
        />

        <Modal
          content={MODALS.IMAGE_UPLOAD}
          childProps={{
            parentObjects: {
              building: currentActionBuilding,
            },
            objectName: currentActionBuildingName,
            noPadding: true,
            setListing: async (newListingData) => {
              const { building: newBuilding } = newListingData
              const newBuildingPartial = {
                images: newBuilding.images,
                neighborhood_photos: newBuilding.neighborhood_photos,
              }

              await onImagesChange({
                buildingId: currentActionBuilding.id,
                payload: newBuildingPartial,
              })
            },
            tabsToShow: [
              IMAGE_UPLOAD_MODAL_TAB_KEYS.BUILDING,
              IMAGE_UPLOAD_MODAL_TAB_KEYS.NEIGHBORHOOD,
            ],
          }}
          open={showUploadModal}
          onClose={() => setShowUploadModal(false)}
        />
      </div>

      {hasBuildings ? (
        <div className="w-full ml-8">
          {/*
            This is a hack to avoid showing the scrollable content behind the sticky column
            It creates a vertical bar that covers the space between the left side of the page and the table
          */}
          <div className="absolute top-[60px] left-0 bottom-4 w-8 bg-white z-10" />

          <TableContainer className="flex-none overflow-visible w-full h-full">
            <Table size="small" className="border-separate pr-8">
              <TableHead className="sticky top-[66px] z-[3]">
                <TableRow>
                  {buildingColumns.map((header, arrayIndex) => {
                    return (
                      <TableHeaderCell
                        key={header.field?.id || header.displayName}
                        allColumns={buildingColumns}
                        header={header}
                        index={arrayIndex}
                        onRefresh={onRefreshValues}
                      />
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((building, rowIndex) => {
                  const rowCount = data.length

                  return (
                    <TableRow key={building.id} data-row-id={building.id}>
                      {building.values.map((cell, cellIndex) => {
                        const header = buildingColumns[cellIndex]
                        const DisplayCell =
                          cellComponentMap[header.fieldDataType.id] ??
                          StringCell

                        const isDescription =
                          header.displayName === 'Building Notes'

                        const isAddress = header.displayName === 'Address'

                        return (
                          <TableCell
                            key={`${header.displayName}-${building.id}`}
                            data-value-id={`${header.displayName}-${building.id}`}
                            className={clsx(
                              'w-[100px] py-3 px-4 h-20 bg-white',
                              'border-0 border-solid border-[#E0E0E0] border-l',
                              'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
                              rowIndex !== 0 && 'border-t',
                              rowIndex === rowCount - 1 && 'border-b',
                              isDescription && 'max-w-[344px]',
                              isAddress && 'min-w-[360px] text-left',
                              cellIndex === 1 && 'border-l-0'
                            )}
                          >
                            <div className="flex max-h-14 items-center gap-1">
                              {isAddress ? (
                                <label
                                  className="size-[58px] flex items-center justify-center cursor-pointer relative group"
                                  onClick={() => {
                                    setCurrentActionBuilding(building)
                                    setShowUploadModal(true)
                                  }}
                                >
                                  <div
                                    className="absolute inset-0 z-10 text-white text-sm font-semibold rounded group-hover:block hidden"
                                    style={{
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                  >
                                    <div className="flex items-center justify-center w-full h-full">
                                      <PlusIcon />
                                    </div>
                                  </div>
                                  <File
                                    file={building.hero_photo || {}}
                                    skipUploading
                                    showAsThumbnail
                                    useMissingBuildingImage
                                    missingFileStyleOverrides={{
                                      backgroundColor: '#E0E0E0',
                                      color: '#666666',
                                    }}
                                  />
                                </label>
                              ) : null}
                              <DisplayCell
                                cell={cell || {}}
                                header={header}
                                onChange={(_, value) => {
                                  onValueChange({
                                    entityId: building.id,
                                    header,
                                    cell,
                                    value,
                                  })
                                }}
                              />
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}

                <TableRow data-id="add-building-row">
                  <TableCell
                    className={clsx(
                      'w-[100px] py-3 px-4 h-20',
                      'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                      'sticky left-8 z-[1] bg-white'
                    )}
                  >
                    <ButtonBase
                      disableRipple
                      onClick={() => setModalName('addBuilding')}
                    >
                      <Typography
                        variant="boldText"
                        color="primary"
                        className="flex items-center gap-[18px]"
                      >
                        <PlusIcon />
                        Add Building
                      </Typography>
                    </ButtonBase>
                  </TableCell>
                  <TableCell
                    className="border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br"
                    colSpan={buildingColumns.length - 1}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="px-8 grow">
          <div className="flex flex-col items-center h-full bg-[#F3F3F3] rounded text-[#666]">
            <div className="relative top-[37%] text-center">
              <BuildingIcon />
              <Typography variant="emptyStateHeader" className="mt-2 mb-1.5">
                Add a building
              </Typography>

              <Typography className="w-[264px] text-center mb-[8.5px]">
                Begin creating your survey or tour book by adding a building.
              </Typography>

              <ButtonBase
                disableRipple
                onClick={() => setModalName('addBuilding')}
              >
                <Typography
                  variant="boldText"
                  color="primary"
                  className="font-semibold leading-[22px]"
                >
                  Add Building
                </Typography>
              </ButtonBase>
            </div>
          </div>
        </div>
      )}

      <FieldOrderDrawer
        open={modalName === 'fieldOrder'}
        fields={buildingColumns}
        onClose={() => setModalName(null)}
      />
    </>
  )
}

function SpacesTab({
  spaceColumns,
  surveyBuildings,
  surveyListings,
  listingValues,
  onValueChange,
  onAddSpace,
  onAddField,
  onRefreshValues,
  onImagesChange,
}) {
  const theme = useTheme()
  const [modalName, setModalName] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [currentActionSpace, setCurrentActionSpace] = useState(null)

  const currentActionSpaceName = useMemo(
    () => (currentActionSpace ? getListingPrimaryName(currentActionSpace) : ''),
    [currentActionSpace]
  )

  const listingCountString =
    surveyListings.length === 1 ? '1 Space' : `${surveyListings.length} Spaces`

  const nextOrder = useMemo(() => {
    const customFields = spaceColumns.filter((column) => !column.reserved)
    if (customFields.length > 0) {
      const lastOrder = customFields[customFields.length - 1].field.order
      return lastOrder ? lastOrder + 1 : undefined
    }

    return undefined
  }, [spaceColumns])

  const data = useMemo(() => {
    const buildingsMap = groupBy(surveyBuildings, 'building.id')
    const valuesByListing = groupBy(listingValues, 'listing_id')
    const listingsByBuilding = groupBy(surveyListings, 'listing.building.id')

    return (
      Object.keys(buildingsMap)
        // Make sure the keys are sorted by the `surveyBuilding` order
        // Since the JS Object's default order does not match our desired order
        .sort((a, b) => buildingsMap[a][0].order - buildingsMap[b][0].order)
        .map((buildingId) => {
          const surveyBuilding = buildingsMap[buildingId][0]
          const { building } = surveyBuilding
          const listings = listingsByBuilding[buildingId] || []

          const mappedListings = listings.map(({ listing }) => {
            const values = []

            spaceColumns.forEach((column) => {
              const modelValue = column.reserved
                ? listing[column.modelName]
                : null
              const customFieldValue = !column.reserved
                ? valuesByListing[listing.id]?.find(
                    (item) => item.custom_field_id === column.field.id
                  )
                : null

              values.push({
                id: column.reserved ? listing.id : customFieldValue?.id,
                value: column.reserved ? modelValue : customFieldValue?.value,
              })
            })

            return {
              ...listing,
              values,
            }
          })

          return { ...building, listings: mappedListings, surveyBuilding }
        })
    )
  }, [surveyBuildings, surveyListings, spaceColumns, listingValues])

  return (
    <>
      <div className="flex justify-between bg-white px-8 py-4 sticky left-0 z-[1]">
        <Typography
          className="my-auto mr-4 leading-7 font-semibold"
          variant="boldText"
        >
          {listingCountString}
        </Typography>

        <div className="flex items-center gap-2">
          <SquareIconButton
            tooltipText="Field Order"
            onClick={() => setModalName('fieldOrder')}
            style={{ marginRight: '8px' }}
          >
            <ReorderIcon />
          </SquareIconButton>

          <Button
            color="primary"
            className="py-0 px-4 h-9 m-auto mr-[1px]"
            onClick={() => setModalName('addField')}
            shrinkButton
          >
            Add Field
          </Button>

          <AddFieldModal
            type="space"
            open={modalName === 'addField'}
            onClose={() => setModalName(null)}
            nextOrder={nextOrder}
            onAddField={onAddField}
          />
        </div>
      </div>

      <Modal
        content={MODALS.IMAGE_UPLOAD}
        childProps={{
          parentObjects: {
            listing: currentActionSpace,
          },
          objectName: currentActionSpaceName,
          noPadding: true,
          setListing: async (newListingData) => {
            const newSpacePartial = {
              images: newListingData.images,
              floorplan_photos: newListingData.floorplan_photos,
            }
            await onImagesChange({
              listingId: currentActionSpace.id,
              payload: newSpacePartial,
            })
          },
          tabsToShow: [
            IMAGE_UPLOAD_MODAL_TAB_KEYS.FLOORPLAN,
            IMAGE_UPLOAD_MODAL_TAB_KEYS.SPACE,
          ],
        }}
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />

      <div className="w-full ml-8">
        {/*
            This is a hack to avoid showing the scrollable content behind the sticky column
            It creates a vertical bar that covers the space between the left side of the page and the table
          */}
        <div className="absolute top-[60px] left-0 bottom-4 w-8 bg-white z-10" />

        <TableContainer className="flex-none overflow-visible w-full h-full">
          <Table size="small" className="border-separate pr-8">
            <TableHead className="sticky top-[66px] z-[3]">
              <TableRow>
                {spaceColumns.map((header, arrayIndex) => {
                  return (
                    <TableHeaderCell
                      key={header.index}
                      allColumns={spaceColumns}
                      index={arrayIndex}
                      header={header}
                      onRefresh={onRefreshValues}
                    />
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((building) => {
                return (
                  <Fragment key={building.id}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          'h-[84px] pt-[36px] pb-5 pl-0 whitespace-nowrap border-0',
                          'sticky left-8 z-[1] bg-white border-r-2'
                        )}
                      >
                        <Typography
                          variant="boldText"
                          className="font-semibold text-sm leading-7"
                        >
                          {building.address}
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={spaceColumns.length - 1}
                        className="border-0 bg-white"
                      />
                    </TableRow>

                    {building.listings.map((listing, listingIndex) => (
                      <TableRow key={listing.id} data-row-id={listing.id}>
                        {listing.values.map((cell, cellIndex) => {
                          const listingCount = building.listings.length

                          const header = spaceColumns[cellIndex]
                          const DisplayCell =
                            cellComponentMap[header.fieldDataType.id] ??
                            StringCell

                          const isSpaceNotes =
                            header.displayName === 'Space Notes'
                          const isSpaceName =
                            header.displayName === 'Space Name'

                          return (
                            <TableCell
                              key={`${header.displayName}-${listing.id}`}
                              data-value-id={`${header.displayName}-${listing.id}`}
                              className={clsx(
                                'w-[100px] py-3 px-4 h-20 bg-white',
                                listingIndex === listingCount - 1 && 'border-b',
                                'border-0 border-solid border-[#E0E0E0] border-l border-t',
                                'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
                                listingIndex === 0 &&
                                  'first:rounded-tl last:rounded-tr',
                                isSpaceNotes && 'max-w-[344px]',
                                cellIndex === 1 && 'border-l-0'
                              )}
                            >
                              <div className="flex max-h-14 items-center gap-1">
                                {isSpaceName ? (
                                  <label
                                    className="size-[50px] flex items-center justify-center cursor-pointer relative group"
                                    onClick={() => {
                                      setCurrentActionSpace(listing)
                                      setShowUploadModal(true)
                                    }}
                                  >
                                    <div
                                      className="absolute inset-0 z-10 text-white text-sm font-semibold rounded group-hover:block hidden"
                                      style={{
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      <div className="flex items-center justify-center w-full h-full">
                                        <PlusIcon />
                                      </div>
                                    </div>
                                    <File
                                      file={listing.hero_image || {}}
                                      skipUploading
                                      showAsThumbnail
                                      useMissingSpaceImage
                                      missingFileStyleOverrides={{
                                        backgroundColor: '#E0E0E0',
                                        color: '#666666',
                                      }}
                                    />
                                  </label>
                                ) : null}
                                <DisplayCell
                                  cell={cell || {}}
                                  header={header}
                                  onChange={(_, value) => {
                                    onValueChange({
                                      entityId: listing.id,
                                      header,
                                      cell,
                                      value,
                                    })
                                  }}
                                />
                              </div>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell
                        className={clsx(
                          'w-[100px] py-3 px-4 h-20',
                          'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                          'sticky left-8 z-[1] bg-white',
                          !building.listings.length &&
                            'border-t border-dotted #E0E0E0'
                        )}
                      >
                        <ButtonBase
                          disableRipple
                          onClick={() => {
                            onAddSpace(building.surveyBuilding.id)
                          }}
                        >
                          <Typography
                            variant="boldText"
                            color="primary"
                            className="flex items-center gap-[18px]"
                          >
                            <PlusIcon />
                            Add Space
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          'border-0 border-dotted border-[#E0E0E0] first:border-l first:rounded-bl border-b last:border-r last:rounded-br',
                          !building.listings.length &&
                            'border-t border-dotted #E0E0E0'
                        )}
                        colSpan={spaceColumns.length - 1}
                      />
                    </TableRow>
                  </Fragment>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <FieldOrderDrawer
        open={modalName === 'fieldOrder'}
        onClose={() => setModalName(null)}
        fields={spaceColumns}
      />
    </>
  )
}

function TableHeaderCell({ allColumns, header, index, onRefresh }) {
  const { id: surveyId } = useParams()
  const theme = useTheme()
  const [newHeader, setNewHeader] = useState({ ...header })
  const [modalName, setModalName] = useState(null)

  const IconComponent = header.icon
  const isReserved = header.reserved

  const isLast = index === allColumns.length - 1
  const isSpacesHeader =
    header.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id

  // If we only have Space Name and Space Notes
  const onlyTwoSpaceColumns = allColumns.length === 2

  useEffect(() => {
    if (header) {
      setNewHeader({ ...header })
    } else {
      setNewHeader({})
    }
  }, [header])

  const {
    survey,
    mutateChangeCustomFieldLabel: renameField,
    mutateDeleteCustomField: deleteField,
    mutateChangeCustomFieldType: changeFieldDataType,
    mutateChangeCustomFieldTemplate: changeFieldType,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  // Menu stuff
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const anchorRef = React.useRef(null)
  const handleClick = () => {
    setAnchorEl(anchorRef.current)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    closeMenu()
  }

  const handleRenameField = () => {
    if (newHeader.displayName !== header.displayName) {
      renameField({
        id: header.field.id,
        newName: newHeader.displayName,
      })
    }
  }

  const onCancelChangeFieldDataType = () => {
    setNewHeader({
      ...newHeader,
      fieldDataType: header.fieldDataType,
    })
    setModalName(null)
  }

  const onCancelChangeFieldType = () => {
    setNewHeader({
      ...newHeader,
      fieldType: header.fieldType,
    })
    setModalName(null)
  }

  return (
    <TableCell
      align="center"
      className={clsx(
        'bg-[#F9F9F9] h-12 max-h-12 py-1.5 pr-3 pl-6',
        'border-0 border-solid border-[#E0E0E0] border-l border-t border-b',
        'first:rounded-tl first:sticky first:left-8 first:border-r-2 first:z-[1] last:rounded-tr last:border-r',
        isSpacesHeader && 'border-b first:rounded-bl last:rounded-br',
        onlyTwoSpaceColumns && 'w-1/2',
        index === 1 && 'border-l-0',
        isReserved && 'pr-6'
      )}
      ref={anchorRef}
      data-field-id={header.field?.id || header.displayName}
    >
      <Tooltip
        title={isReserved ? "LeaseUp fields can't be edited or deleted" : ''}
        disableFocusListener
        disableTouchListener
      >
        <div
          className="text-[#666] flex items-center cursor-pointer"
          onClick={handleClick}
        >
          <IconComponent className="mr-2" />
          <Typography
            noWrap
            variant="body2"
            style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
          >
            {header.displayName.toUpperCase()}
          </Typography>
          {!isReserved ? <TextChevronDown className="text-[#666]" /> : null}
        </div>
      </Tooltip>

      {!isReserved ? (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
          className="w-[240px]"
          elevation={2}
          // Open bottom centered
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isLast ? 'right' : 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: isLast ? 'right' : 'center',
          }}
          PaperProps={{
            style: {
              width: '240px',
              minWidth: '240px',
              maxHeight: '375px',
            },
          }}
        >
          <div className="text-[#666] pt-3 pb-1.5 px-3 items-center">
            <Typography variant="h3" className="leading-3 font-med">
              Field Name
            </Typography>
          </div>
          <div className="flex pt-0 px-3 items-center">
            {/* The name of the field */}
            {newHeader.reserved || newHeader.disableAllEditing ? (
              <>
                <Typography className="pt-1.5 pb-[7px]">
                  {newHeader.displayName}
                </Typography>
                <LockIcon className="ml-auto" />
              </>
            ) : (
              <WhiteTextField
                fullWidth
                id="standard-basic"
                value={newHeader.displayName}
                variant="standard"
                color="primary"
                onKeyDown={(e) => {
                  e.stopPropagation()
                  if (e.key === 'Enter') {
                    handleRenameField()
                    closeMenu()
                  }
                }}
                InputProps={{
                  endAdornment:
                    newHeader.reserved || newHeader.disableAllEditing ? (
                      ''
                    ) : (
                      <InputAdornment position="end">
                        <TableClearIcon
                          className="cursor-pointer mr-[-5px] text-[#e0e0e0]"
                          onClick={() => {
                            setNewHeader({ ...newHeader, displayName: '' })
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                        />
                      </InputAdornment>
                    ),
                  autoFocus: true,
                }}
                FormHelperTextProps={{ style: { ...theme.typography.h5 } }}
                onChange={(event) =>
                  setNewHeader({
                    ...newHeader,
                    displayName: event.target.value,
                  })
                }
                onBlur={() => {
                  handleRenameField()
                }}
              />
            )}
          </div>

          {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
            <Divider sx={{ my: 0.5 }} className="items-center mx-3 px-0" />
          )}

          {/* Menu section for the field data type */}
          {!!(!newHeader.reserved && !newHeader.disableAllEditing) && (
            <FieldDataTypeMenuSection
              classesIn={{
                fieldHeaderMenuSection: 'pt-8',
              }}
              onClick={(newFieldDataType) => {
                setNewHeader({
                  ...newHeader,
                  fieldDataType: newFieldDataType,
                })
                setModalName('changeFieldDataType')
                closeMenu()
              }}
              fieldDataTypeId={newHeader.fieldDataType.id}
            />
          )}

          {/* Field types - space or building */}
          {!!(!newHeader.reserved && !newHeader.disableAllEditing) && [
            <div
              key="fieldTypeKey"
              className="text-[#666] pb-1.5 pt-5 px-3 items-center"
            >
              <Typography variant="h3" className="leading-3 font-med">
                Field Type
              </Typography>
            </div>,
            BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_TYPES_ORDERED.map(
              (fieldType) => {
                const FieldIconComponent = fieldType.icon
                return (
                  <MenuItem
                    className="px-3 items-center"
                    disableGutters
                    key={fieldType.id}
                    onClick={() => {
                      setNewHeader({
                        ...newHeader,
                        fieldType,
                      })
                      setModalName('changeFieldType')
                      closeMenu()
                    }}
                  >
                    <FieldIconComponent className="mr-3 text-[#111]" />
                    <Typography color="textPrimary" variant="h3">
                      {fieldType.name}
                    </Typography>
                    {fieldType.id === newHeader.fieldType.id && (
                      <MenuSelectedItemIcon className="ml-auto text-[#666]" />
                    )}
                  </MenuItem>
                )
              }
            ),
          ]}

          {/* Option to remove a field */}
          {!newHeader.disableAllEditing && [
            <div
              key="fieldMatchingKey"
              className="text-[#666] pb-1.5 pt-5 px-3 items-center"
            >
              <Typography variant="h3" className="leading-3 font-med">
                Remove
              </Typography>
            </div>,
            <MenuItem
              key="removeColumn"
              disableGutters
              onClick={() => {
                setModalName('deleteField')
                closeMenu()
              }}
              className="px-3 items-center"
            >
              <DeleteIcon className="mr-3 text-[#111]" />
              <Typography variant="h3">Delete Field</Typography>
            </MenuItem>,
          ]}
        </Menu>
      ) : null}

      <DeleteFieldModal
        fieldName={newHeader.displayName}
        surveyName={survey.name}
        isCustomField
        deleteSpaceField={() => {
          deleteField({ id: newHeader.field.id })
        }}
        isBuildingField={
          newHeader.fieldType.id ===
          BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
        }
        open={modalName === 'deleteField'}
        onClose={() => setModalName(null)}
        textOverride={`Are you sure you want to delete the ${newHeader.displayName} field? This will also delete its data. This action cannot be undone.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldDataType',
          onClose: onCancelChangeFieldDataType,
        }}
        onClose={onCancelChangeFieldDataType}
        onConfirm={async () => {
          await changeFieldDataType({
            id: newHeader.field.id,
            newType: newHeader.fieldDataType.id,
            newValue: null,
            optimistic: false,
          })
          setModalName(null)
          await onRefresh()
        }}
        title="Change Data Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change ${newHeader.displayName} from ${header.fieldDataType.name} to ${newHeader.fieldDataType.name}? Doing so may cause some or all of the field’s data to be permanently erased.`}
      />

      <ConfirmModalComponent
        ModalComponentProps={{
          open: modalName === 'changeFieldType',
          onClose: onCancelChangeFieldType,
        }}
        onClose={onCancelChangeFieldType}
        onConfirm={() => {
          changeFieldType({
            id: newHeader.field.id,
            newType: newHeader.fieldType.templateType,
          })
          setModalName(null)
        }}
        title="Change Field Type"
        confirmButtonLabel="Confirm"
        text={`Are you sure you want to change the ${newHeader.displayName} field type from ${header.fieldType.name} to ${newHeader.fieldType.name}? Doing so will cause the field’s data to be permanently erased. This cannot be undone.`}
      />
    </TableCell>
  )
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({ cell, header, onChange }) => {
  const addressValue = {
    GOOGLE_ADDRESS: { ...cell.value },
    ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={onChange}
      value={addressValue || null}
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
      extraProps={{
        isBulkEdit: true,
        buildingUrl: getViewBuildingRoute(cell.surveyId, cell.id),
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
const NumericFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
const DateFieldCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

const ListSemicolonCell = ({ cell, header, onChange }) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={onChange}
    />
  )
}

const StringCell = ({
  cell,
  header,
  onChange,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={onChange}
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)
const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
    '& .MuiInputBase-input': {
      height: '22px',
    },
  },
})(TextField)

const FieldOrderDrawer = ({ open, onClose, fields }) => {
  const { id: surveyId } = useParams()
  const fieldType = fields[0].fieldType.name
  const [orderedFields, setOrderedFields] = useState(fields)

  const {
    survey,
    mutateSetCustomFieldOrder: setFieldsOrder,
    mutateDeleteCustomField: deleteField,
  } = useSurveyCustomFieldsSelector({ surveyId, enabled: Boolean(surveyId) })

  useEffect(() => {
    setOrderedFields(fields)
  }, [fields])

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedFields]
  )

  const didOrderChange = useMemo(() => {
    return (
      fields.map((item) => item.displayName).join(',') !==
      orderedFields.map((item) => item.displayName).join(',')
    )
  }, [fields, orderedFields])

  return (
    <Dialog
      id="dialog-backdrop"
      maxWidth={false}
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <Drawer
        anchor="right"
        classes={{
          paper: 'drawer-width',
        }}
        open={open}
        onClose={onClose}
        variant="persistent"
      >
        <div className="flex border-solid border-0 border-b border-b-[#E0E0E0] pt-[15px] pr-4 pb-4 pl-6">
          <Typography variant="h2" className="flex text-lg leading-6 my-auto">
            Field Order
          </Typography>
          <div className="ml-auto">
            <SquareIconButton onClick={() => onClose()}>
              <CloseIcon />
            </SquareIconButton>
          </div>
        </div>
        <Fade in={open}>
          <div className="p-6">
            <div>
              <Typography variant="bodyBold" className="mb-4">
                {`${fields.length} ${fieldType} Fields`}
              </Typography>
            </div>

            <DndProvider backend={BACKEND_FOR_DND}>
              <div className="flex flex-col bg-[#F9F9F9] rounded py-3 px-2 gap-2">
                {orderedFields.map((item, index) => {
                  return (
                    <DraggableItem
                      key={item?.field?.id || item.displayName}
                      item={item}
                      index={index}
                      moveField={moveField}
                      onDragEnd={() => {
                        const localNewFieldOrder = {}
                        orderedFields.forEach((column, index) => {
                          if (column.reserved) return
                          localNewFieldOrder[column.field.id] = index
                        })

                        if (didOrderChange) {
                          setFieldsOrder({
                            fieldOrder: localNewFieldOrder,
                            isBuildingFields:
                              fieldType ===
                              BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.name,
                          })
                        }
                      }}
                      onDeleteField={deleteField}
                      survey={survey}
                    />
                  )
                })}
              </div>
            </DndProvider>
          </div>
        </Fade>
      </Drawer>
    </Dialog>
  )
}

const AddFieldModal = ({ open, onClose, type, onAddField, nextOrder }) => {
  const [fieldName, setFieldName] = useState('')
  const { t } = useTranslation()

  const onSave = async () => {
    const { error, data } = await onAddField({
      label: fieldName,
      order: nextOrder,
      type,
    })

    if (error) {
      SnackbarUtils.error(error)
    } else {
      const addedField = data.custom_fields.at(-1)
      SnackbarUtils.success(t('toasts.fieldAdded'))
      setFieldName('')
      onClose()

      // FIXME: Probably find a better way to do this
      if (addedField.id) {
        document
          .querySelector(`th[data-field-id="${addedField.id}"]`)
          .scrollIntoView({
            behavior: 'smooth',
          })
      }
    }
  }

  return (
    <ActionModal2
      open={open}
      title="Add Field"
      action="Add to Survey"
      // loading={isSaving}
      onAction={onSave}
      onClose={() => {
        setFieldName('')
        onClose()
      }}
    >
      <form
        style={{ marginTop: '32px', marginBottom: '40px' }}
        onSubmit={(e) => {
          e.preventDefault()
          onSave()
        }}
      >
        <TextField
          label="Field Name"
          variant="outlined"
          fullWidth
          autoFocus
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
        />
      </form>
    </ActionModal2>
  )
}

const DraggableItem = ({
  item,
  index,
  moveField,
  onDragEnd,
  onDeleteField,
  survey,
}) => {
  const isReserved = item.reserved
  const [modalName, setModalName] = useState(null)

  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index, field: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop() {
      onDragEnd()
    },
  })

  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <div
      className="flex rounded py-2 px-3 bg-white border-solid border border-[#E0E0E0] gap-1.5 items-center"
      ref={!isReserved ? previewRef : null}
      data-handler-id={!isReserved ? handlerId : null}
      style={{ opacity }}
    >
      <SquareIconButton
        tooltipText={isReserved ? 'Can’t move this field' : ''}
        disabled={isReserved}
        ref={ref}
      >
        {isReserved ? <LockIcon /> : <DragIcon className="cursor-grab" />}
      </SquareIconButton>

      <Typography variant="bodyBold" className="grow">
        {item.displayName}
      </Typography>

      <SquareIconButton
        tooltipText={isReserved ? 'Can’t delete this field' : ''}
        disabled={isReserved}
        className={clsx(isReserved && 'opacity-50')}
        onClick={() => {
          if (!isReserved) {
            setModalName('deleteField')
          }
        }}
      >
        <DeleteIcon />
      </SquareIconButton>

      {/* FIXME: Putting this for now. Implement a better way after */}
      <DeleteFieldModal
        fieldName={item.displayName}
        surveyName={survey.name}
        isCustomField
        deleteSpaceField={() => {
          onDeleteField({ id: item.field.id })
        }}
        isBuildingField={
          item.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
        }
        open={modalName === 'deleteField'}
        onClose={() => setModalName(null)}
        textOverride={`Are you sure you want to delete the ${item.displayName} field? This will also delete its data. This action cannot be undone.`}
      />
    </div>
  )
}

const cellComponentMap = {
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id]: AddressFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id]: DateFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id]: ListSemicolonCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id]:
    MultilineStringCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id]: StringCell,
}

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

const isBadAuthResponseMessage = (responseMessage) => {
  return [
    'You do not have permission to perform this action.',
    'Authentication credentials were not provided.',
  ].includes(responseMessage)
}
