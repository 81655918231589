import { toast } from '~/legacy/utils/notifications';

import {
  downloadFileExportedPdf,
  refreshAfterDeleteListing,
  refreshAllAfterDeleteListing,
  addCustomAmenities,
  setEditPageInitialData,
} from './postfetch';

import { setListingLoading } from './prefetch';

export default {
  getListings: {
    url: '/listings/search/', // asyncPrefetch:asyncFn,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    prefetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(true))
      },
    ],
    postfetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(false))
      },
    ],
  },
  getListing: {
    url: '/listings/:id/',
    postfetch: [addCustomAmenities],
    changeOnAction: {
      'api(.)(.)updateListing_success': (state) => ({ ...state, data: {} }),
    },
  },
  getSurveyListing: {
    url: '/listings/:id/survey_listing/',
  },
  getListingForEdit: {
    url: '/listings/:id/',
    postfetch: [addCustomAmenities, setEditPageInitialData],
    changeOnAction: {
      'api(.)(.)updateListing_success': (state) => ({ ...state, data: {} }),
    },
  },
  getSurveyListings: {
    url: '/surveys/:id/all_listings/',
    options: {
      method: 'POST',
    },
    transformer: (data, oldData = []) => {
      if (data) return data
      return oldData
    },
    postfetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(false))
      },
    ],
  },
  getMyListings: {
    url: '/listings/search/',
    options: {
      method: 'POST',
    },
    changeOnAction: {
      '@@INIT': (store) => ({ ...store, data: [] }),
    },
    prefetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(true))
      },
    ],
    postfetch: [
      ({ dispatch }) => {
        dispatch(setListingLoading(false))
      },
    ],
  },
  getBrokerClientListings: {
    url: '/listings/:id/get_client_listings/',
    options: {
      method: 'GET',
    },
  },
  saveListing: {
    url: '/listings/',
    options: {
      method: 'POST',
    },
  },
  saveListingsBulk: {
    url: '/listings/bulk_create/',
    options: {
      method: 'POST',
    },
  },
  saveListingToUser: {
    url: '/listings/:id/add_to_user/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => {
        toast('Listing has been saved successfully', { appearance: 'success' })
      },
    ],
  },
  removeListingFromUser: {
    url: '/listings/:id/remove_from_user/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () =>
        toast('Removed listing from user successfully', {
          appearance: 'success',
        }),
    ],
  },
  updateListing: {
    url: '/listings/:id/partial_update_old/',
    options: {
      method: 'PATCH',
    },
    // onSuccess: [
    //   () =>
    //     toast('Listing has been updated successfully', {
    //       appearance: 'success',
    //     }),
    // ],
    onError: [
      (result) => {
        if (
          result &&
          result.message &&
          result.message.file_ids &&
          result.message.file_ids.length === 1 &&
          result.message.file_ids[0].includes('it should contain no more than')
        ) {
          toast('Only 50 photos and attachments are allowed for a listing.', {
            appearance: 'danger',
          })
        } else if (
          result &&
          result.message &&
          result.message.building &&
          result.message.building.file_ids &&
          result.message.building.file_ids.length === 1 &&
          result.message.building.file_ids[0].includes(
            'it should contain no more than'
          )
        ) {
          toast('Only 50 photos and attachments are allowed for a building.', {
            appearance: 'danger',
          })
        }
      },
    ],
  },
  deleteListing: {
    url: '/listings/:id/',
    options: {
      method: 'DELETE',
    },
    onSuccess: [
      refreshAfterDeleteListing,
      () =>
        toast('Listing has been deleted successfully', {
          appearance: 'success',
        }),
    ],
  },
  deleteListingSimple: {
    url: '/listings/:id/',
    options: {
      method: 'DELETE',
    },
    onSuccess: [
      () =>
        toast('Listing has been deleted successfully', {
          appearance: 'success',
        }),
    ],
  },
  removeListingFromClient: {
    url: '/listings/:id/',
    options: {
      method: 'DELETE',
    },
    onSuccess: [
      refreshAfterDeleteListing,
      () =>
        toast('Listing has been removed successfully', {
          appearance: 'success',
        }),
    ],
  },
  exportToPdfListings: {
    url: '/export/',
    options: {
      method: 'GET',
    },
    onSuccess: [
      downloadFileExportedPdf,
      () => toast('Exported Successfully', { appearance: 'success' }),
    ],
  },
  imageUpload: {
    url: '/upload/',
    prefetch: [
      /* eslint-disable */
      (obj) => {
        //delete obj.options.headers['Content-Type'];
        //delete obj.options.headers['content-type'];
      },
      /* eslint-enable */
    ],
    options: {
      method: 'POST',
    },
    transformer: (data, oldData = {}) => {
      if (data) return { ...oldData, [data.original_filename]: data }
      return oldData
    },
    changeOnAction: {
      remove_image_upload_state: (state, action) => {
        const newState = { ...state }
        delete newState.data[action.payload]
        return newState
      },
      clean_image_upload_state: (state) => ({ ...state, data: {} }),
    },
  },
  csvUpload: {
    url: '/upload_csv/',
    prefetch: [
      /* eslint-disable */
      (obj) => {
        //delete obj.options.headers['Content-Type'];
        //delete obj.options.headers['content-type'];
      },
      /* eslint-enable */
    ],
    options: {
      method: 'POST',
    },
  },
  s3Upload: {
    options: {
      method: 'POST',
    },
  },
  blueprintUpload: {
    url: 'https://api.cloudinary.com/v1_1/dx4ku5l2k/image/upload',
    options: {
      method: 'POST',
      headers: {
        Authorization: '',
        'Content-Type': 'multipart/form-data',
      },
    },
    transformer: (data, oldData = {}) => {
      if (data) return { ...oldData, [data.original_filename]: data }
      return oldData
    },
  },
  assignListingToProject: {
    url: '/listings/assign_to_project/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      (event) => {
        if (
          event &&
          event.data &&
          event.data.data &&
          event.data.data === 'already exists'
        ) {
          toast('Listing already assigned to this project', {
            appearance: 'success',
          })
        } else {
          toast('Assigned Successfully', { appearance: 'success' })
        }
      },
    ],
  },
  archiveListing: {
    url: '/listings/:id/archive/',
    options: {
      method: 'POST',
    },
    postfetch: [
      refreshAfterDeleteListing,
      refreshAllAfterDeleteListing,
      () => toast('Archived Successfully', { appearance: 'success' }),
    ],
  },
  unArchiveListing: {
    url: '/listings/:id/unarchive/',
    options: {
      method: 'POST',
    },
    postfetch: [
      refreshAfterDeleteListing,
      refreshAllAfterDeleteListing,
      () => toast('Unarchived Successfully', { appearance: 'success' }),
    ],
  },
  publishListing: {
    url: '/listings/:id/publish/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Listing Published Successfully', { appearance: 'success' }),
    ],
  },
  shareWithTenantListing: {
    url: '/listings/:id/share/',
    options: {
      method: 'POST',
    },
    onSuccess: [() => toast('Shared Successfully', { appearance: 'success' })],
  },
  unshareWithTenantListing: {
    url: '/listings/:id/unshare/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Unshared Successfully', { appearance: 'success' }),
    ],
  },
  declineListing: {
    url: '/listings/:id/decline/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Listing successfully declined', { appearance: 'success' }),
    ],
  },
  undeclineListing: {
    url: '/listings/:id/undecline/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Listing successfully undeclined', { appearance: 'success' }),
    ],
  },
  tourRequestListing: {
    url: '/listings/:id/tour_request/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Tour successfully requested', { appearance: 'success' }),
    ],
  },
  unTourRequestListing: {
    url: '/listings/:id/untour_request/',
    options: {
      method: 'POST',
    },
    onSuccess: [
      () => toast('Tour successfully unrequested', { appearance: 'success' }),
    ],
  },
  addListingCustomField: {
    url: '/listings/:id/add_custom_field/',
    options: {
      method: 'POST',
    },
  },
  addListingCustomFieldsFromListing: {
    url: '/listings/add_custom_fields_from_another_listing/',
    options: {
      method: 'PUT',
    },
  },
  updateListingCustomField: {
    url: '/listings/:id/update_custom_field/',
    options: {
      method: 'POST',
    },
  },
  deleteListingCustomField: {
    url: '/listings/:id/delete_custom_field/',
    options: {
      method: 'POST',
    },
  },
  getBuildingListings: {
    url: '/listings/building_listings/',
    options: {
      method: 'GET',
    },
  },
  getListingActivity: {
    url: '/listings/:id/activity/',
    options: {
      method: 'GET',
    },
  },
  quickEntryCreateListing: {
    url: '/listings/quick_entry_create/',
    options: {
      method: 'POST',
    },
  },
  getDatabaseListings: {
    url: '/databases/:id/all_listings/',
    options: {
      method: 'POST',
    },
    transformer: (data, oldData = []) => {
      if (data) return data
      return oldData
    },
    prefetch: [
      ({ dispatch }) => {
        console.log('getDatabaseListings prefetch')
        dispatch(setListingLoading(true))
      },
    ],
    postfetch: [
      ({ dispatch }) => {
        console.log('getDatabaseListings postfetch')
        dispatch(setListingLoading(false))
      },
    ],
  },
}
