import React from 'react'
import { useParams } from 'react-router'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { BulkEditCollection } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/bulk-edit-collection'
import { CollectionProvider } from '~/legacy/pages/Surveys/Survey/bulk-edit-collection/collection-context'
import { SearchProvider } from '~/legacy/components/search-bar/search-context'

export default function (props) {
  const { id } = useParams()
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <CollectionProvider surveyId={id} databaseId={null}>
        <SearchProvider>
          <BulkEditCollection {...props} />
        </SearchProvider>
      </CollectionProvider>
    </QueryParamProvider>
  )
}

export function BulkEditDatabase(props) {
  const { id } = useParams()

  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <CollectionProvider surveyId={null} databaseId={id}>
        <SearchProvider>
          <BulkEditCollection {...props} />
        </SearchProvider>
      </CollectionProvider>
    </QueryParamProvider>
  )
}