import { useMemo } from 'react'
import { useCollectionContext } from '../collection-context'

/**
 * Calculate distinct values for each column in either buildings or spaces table
 * @param {Object} params
 * @param {Array} params.columns - Column definitions (buildingColumns or spaceColumns)
 * @param {boolean} params.isNested - Whether data has nested structure (true for spaces, false for buildings)
 */
export const useColumnStats = ({ columns, isNested = false }) => {
  const { 
    buildingValues, 
    listingValues,
    loading 
  } = useCollectionContext()
  
  return useMemo(() => {
    const stats = {}
    
    // Don't calculate stats until data is loaded
    if (loading) {
      return stats
    }
    
    columns.forEach(column => {
      const key = column.field?.id || column.displayName
      const distinctValues = new Set()
      
      // Process all values from the complete dataset
      const values = isNested ? listingValues : buildingValues
      values?.forEach(value => {
        if (value.custom_field_id === column.field?.id && value.value != null) {
          distinctValues.add(formatValue(value.value))
        }
      })

      stats[key] = {
        distinctCount: distinctValues.size,
        fullDistinctValues: distinctValues,
        fullDistinctCount: distinctValues.size,
        fieldType: column.fieldDataType?.id,
        isReserved: column.reserved,
        displayName: column.displayName
      }
    })

    return stats
  }, [columns, isNested, listingValues, buildingValues, loading])
}

/**
 * Format cell value for consistent comparison
 */
const formatValue = (value) => {
  if (value === null || value === undefined) return ''
  
  // Handle address objects
  if (typeof value === 'object' && value.address) {
    return value.address
  }
  
  return String(value).trim().toLowerCase()
} 