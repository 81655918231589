import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useDispatch , useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'
import {
  setSurveyListings,
  setSurveyBuildings,
} from '~/legacy/store/actions/viewSurvey'
import { useBuildingCustomFields } from './use-building-custom-fields'
import { useListingCustomFields } from './use-listing-custom-fields'
import { useRedirectNoPermission } from './redirectNoPermissions'

const isBadAuthResponseMessage = (responseMessage) => {
  return [
    'You do not have permission to perform this action.',
    'Authentication credentials were not provided.',
  ].includes(responseMessage)
}

export const CollectionContext = createContext()

export const CollectionProvider = ({ surveyId, databaseId, children }) => {
  const id = surveyId || databaseId
  const useDatabases = !!databaseId
  const idParam = useDatabases ? { databaseId } : { surveyId }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [isSurveyListingsLoading, setIsSurveyListingsLoading] = useState(false)
  const [error, setError] = useState(null)
  const user = useSelector((state) => state.user)
  const redirectNoPermissionUser = useRedirectNoPermission(user)

  // SWR
  const {
    survey,
    buildingCustomFields,
    listingCustomFields,
    isLoading: isCustomFieldsLoading,
    mutateCreateCustomField: createCustomField,
    mutateChangeCustomFieldLabel: renameField,
    mutateDeleteCustomField: deleteField,
    mutateChangeCustomFieldType: changeFieldDataType,
    mutateChangeCustomFieldTemplate: changeFieldType,
    mutateSetCustomFieldOrder: setFieldsOrder,
  } = useSurveyCustomFieldsSelector({
    ...idParam,
    enabled: Boolean(id),
  })

  const {
    buildingValues,
    handleBuildingValueChange,
    isBuildingValuesLoading,
    buildingColumns,
  } = useBuildingCustomFields({
    ...idParam,
    buildingCustomFields,
  })

  const {
    listingValues,
    handleListingValueChange,
    isListingValuesLoading,
    spaceColumns,
  } = useListingCustomFields(surveyId, databaseId, listingCustomFields)

  const anyLoading =
    loading ||
    isSurveyListingsLoading ||
    isBuildingValuesLoading ||
    isListingValuesLoading ||
    isCustomFieldsLoading

  const loadCollection = ({ filters = {}, customFieldFilters = {}, sortColumn, sortOrder }) => {     
    setLoading(true)
    dispatch(setSurveyListings([]))
    setIsSurveyListingsLoading(true)

    const apiMethod = databaseId
      ? Api.getDatabaseListings
      : Api.getSurveyListings

    const apiParams = {
      id: surveyId || databaseId,
      body: {
        filters: { ...filters, sortColumn, sortOrder },
        customFieldFilters,
        sortColumn,
        sortOrder,
      },
    }
    console.log('loadCollection - API call params:', apiParams)
    setError(null)

    try {
      apiMethod(apiParams)
      .then((results) => {
        if (isBadAuthResponseMessage(results.detail)) {
          redirectNoPermissionUser()
        } else if (!results) {
          console.log('No survey listings found. Filters:', { filters, customFieldFilters, sortColumn, sortOrder })
        } else {
          dispatch(setSurveyListings(results.survey_listings))
          dispatch(setSurveyBuildings(results.survey_buildings))
        }
      })
      .finally(() => {
        setIsSurveyListingsLoading(false)
      })
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const contextValue = useMemo(
    () => ({
      survey,
      loading: anyLoading,
      surveyId,
      databaseId,
      error,
      buildingCustomFields,
      listingCustomFields,
      buildingValues,
      listingValues,
      buildingColumns,
      spaceColumns,
      createCustomField,
      renameField,
      deleteField,
      changeFieldDataType,
      changeFieldType,
      handleBuildingValueChange,
      handleListingValueChange,
      setFieldsOrder,
      loadCollection,
    }),
    [
      survey,
      anyLoading,
      surveyId,
      databaseId,
      error,
      buildingCustomFields,
      listingCustomFields,
      buildingValues,
      listingValues,
      buildingColumns,
      spaceColumns,
      loadCollection,
    ]
  )

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  )
}

export const useCollectionContext = () => {
  const context = useContext(CollectionContext)
  if (context === undefined) {
    throw new Error(
      'useCollectionContext must be used within a CollectionProvider'
    )
  }
  return context
}
